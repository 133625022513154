import {datadogLogs} from '@datadog/browser-logs';
import Auth from 'Auth';
import {Button, Collapse, Space, message} from 'antd';
import React from 'react';

const handleRTKQError = (error, meta) => {
  console.error('error', JSON.stringify(error, null, 2));
  console.error('meta', JSON.stringify(meta, null, 2));

  handleApiError({
    status: error?.status ?? 500,
    data: {
      detail: error?.data?.detail ?? 'RTK Query Error',
      location: window.location.href,
      url: meta.request?.url,
      user: {
        id: Auth.getUser()?.id,
        username: Auth.getUser()?.username,
        email: Auth.getUser()?.email,
        ps_site_code: Auth.getUser()?.ps_site_code,
        permissions: Auth.getPermissions(),
      },
    },
  });
};

const reportErrorToIT = (error) => {
  const message = `Hi!\n\nAn error occurred on TRACK. \n\nThese are the error details:\n${JSON.stringify(error, null, 2)}`;
  window.open(
    `mailto:itsupport@uncommonschools.org?subject=TRACK Error&body=${encodeURIComponent(message)}`
  );
};

const handleApiError = (error) => {
  datadogLogs.logger.error('RTK Query Error', {}, error);

  console.error('handleApiError', JSON.stringify(error, null, 2));

  const unauthenticatedContent = (
    <div>
      <p>
        Your session has expired.
        <br />
        You will need to login again to access TRACK.
      </p>
      <Space>
        <Button
          onClick={() => {
            localStorage.setItem('redirect', window.location.pathname);
            Auth.logout();
            message.destroy();
            window.location.href = '/login';
          }}
          type="primary"
        >
          Log In
        </Button>
        <Button onClick={() => reportErrorToIT(error)}>
          Report to IT Support
        </Button>
        <Button onClick={() => message.destroy()}>Close</Button>
      </Space>
    </div>
  );

  const unauthorizedContent = (
    <div>
      <p>
        You do not have permission to access TRACK.
        <br />
        Please contact IT to revise permissions.
      </p>
      <Space>
        <Button onClick={() => reportErrorToIT(error)}>
          Report to IT Support
        </Button>
        <Button onClick={() => message.destroy()}>Close</Button>
      </Space>
    </div>
  );

  const permissionDeniedContent = (
    <div>
      <p>
        You don't have permission to view this page.
        <br />
        If you believe this is an error, please try to log in again.
        <br />
        If the issue persists, please contact IT Support.
      </p>
      <Space>
        <Button onClick={() => window.history.back()}>Back</Button>
        <Button
          onClick={() => {
            localStorage.setItem('redirect', window.location.pathname);
            Auth.logout();
            message.destroy();
            window.location.href = '/login';
          }}
          type="primary"
        >
          Log In Again
        </Button>
        <Button onClick={() => reportErrorToIT(error)}>
          Report to IT Support
        </Button>
      </Space>
    </div>
  );

  const missingResourceContent = (
    <div>
      <p>
        The requested resource was not found.
        <br />
        Please make sure the URL is correct.
      </p>
      <Space>
        <Button onClick={() => reportErrorToIT(error)}>
          Report to IT Support
        </Button>
        <Button onClick={() => message.destroy()}>Close</Button>
      </Space>
    </div>
  );

  const serverErrorContent = (
    <div>
      <p>
        An error occurred on the server.
        <br />
        Please try again or contact support if the issue persists.
      </p>
      <Space>
        <Button onClick={() => reportErrorToIT(error)}>
          Report to IT Support
        </Button>
        <Button onClick={() => message.destroy()}>Close</Button>
      </Space>
    </div>
  );

  const unexpectedErrorContent = (
    <div>
      <p>
        An unexpected error occurred.
        <br />
        Please try again or contact support if the issue persists.
      </p>
      <Space>
        <Button onClick={() => reportErrorToIT(error)}>
          Report to IT Support
        </Button>
        <Button onClick={() => message.destroy()}>Close</Button>
      </Space>
    </div>
  );

  const errorTypes = (err) => {
    switch (err.status) {
      case 401:
        return [
          'Invalid Token',
          'Authentication credentials were not provided.',
          'Unauthorized',
        ].indexOf(err?.data?.detail) !== -1
          ? unauthenticatedContent
          : unauthorizedContent;
      case 403:
        return permissionDeniedContent;
      case 404:
        return missingResourceContent;
      case 500:
        return err.config?.data?.indexOf('{"code":null}') !== -1
          ? unauthorizedContent
          : serverErrorContent;
      default:
        return unexpectedErrorContent;
    }
  };

  message.warning({
    content: (
      <>
        {error ? errorTypes(error) : errorTypes({status: 500})}
        <Collapse ghost style={{textAlign: 'start', fontSize: '12px'}}>
          <Collapse.Panel header="Details" key="1">
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </Collapse.Panel>
        </Collapse>
      </>
    ),
    duration: 0,
    key: 'apiError',
  });
};

export {handleRTKQError, handleApiError};
