import {message} from 'antd';
import {emptySplitApi} from 'api/apiSlice';

const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    get2fa: builder.query({
      query: () => '/auth/setup-2fa/',
    }),
    verify2fa: builder.mutation({
      query: (data) => ({
        url: `/auth/verify-2fa/`,
        method: 'POST',
        body: data,
      }),
      transformErrorResponse: (response) =>
        message.warning(response.data.detail),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: `/auth/login/`,
        method: 'POST',
        body: data,
      }),
      transformErrorResponse: (response) =>
        message.warning(response.data.detail),
    }),
  }),
});

export const {useGet2faQuery, useVerify2faMutation, useLoginMutation} = authApi;
