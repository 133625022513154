import Auth from 'Auth';
import DataPortalDisplay from 'components/dataPortal/DataPortalDisplay';
import PageForm from 'components/dataPortal/PageForm';
import React from 'react';
import {useLocation} from 'react-router-dom';

const DataPortalPage = () => {
  const location = useLocation();
  const editorMode =
    location.pathname.endsWith('/editor') && Auth.accessToDataPortalEditor();

  return (
    <div>
      <div className="flex-column">
        {editorMode && <PageForm />}
        <DataPortalDisplay editorMode={editorMode} />
      </div>
    </div>
  );
};

export default DataPortalPage;
