import {HotTable} from '@handsontable/react';
import {generateUniqueId, stringToFloat} from 'Utils';
import {Alert, Button, Popconfirm, Segmented, Spin, message} from 'antd';
import {
  useFetchColumnOptionsQuery,
  useFetchSeedDataQuery,
  useSetSeedDataMutation,
} from 'api/seedsSlice';
import {
  CustomAutocompleteEditor,
  columnTypes,
  dropdownValidator,
  iconRenderer,
  multiSelectRenderer,
  multiselectValidator,
  safeHTMLRenderer,
  settings,
  textValidator,
} from 'components/seeds/HandsonSettings';
import {statuses} from 'consts';
import Fuse from 'fuse.js';
import _ from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';

// statuses:
// - removed: the row is empty
// - duplicate: the row has duplicate primary keys or unique columns
// - edited: the row has been edited
// - invalid: the row has invalid cells (list of invalid cells)
// - blank: the row has empty cells (list of empty cells)
// - newRow: the row is new

const TableEditor = ({seedId}) => {
  const [columns, setColumns] = useState([]);
  const [autocompleteCols, setAutocompleteCols] = useState('');
  const [statusCounts, setStatusCounts] = useState({
    removed: 0,
    duplicate: 0,
    edited: 0,
    invalid: 0,
    blank: 0,
    newRow: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [loading, setLoading] = useState(false);
  const [primaryKeyCols, setPrimaryKeyCols] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  // const [calculatingStatuses, setCalculatingStatuses] = useState(false);

  const [saveSeedData] = useSetSeedDataMutation();
  const {data: seedData, isLoading: loadingData} = useFetchSeedDataQuery(
    seedId,
    {
      skip: !seedId,
    }
  );
  const {data: autocompleteOptions, isLoading: loadingOptions} =
    useFetchColumnOptionsQuery(autocompleteCols, {
      skip: autocompleteCols === '',
    });

  const hotRef = useRef(null);

  useEffect(() => {
    if (!seedData || !seedId) return;
    let cols = seedData?.metadata?.columns?.length
      ? [...seedData?.metadata.columns]
      : [];

    const tempCols = [
      {name: '', type: 'text', key: 'status'},
      {name: '___primaryKey', type: 'text', key: 'primaryKey'},
    ];
    cols.forEach((col) => {
      const baseCol = {
        ...col,
        linkedCols: col.linked_columns?.length
          ? [
              {source_column: col.source_column, name: col.name},
              ...col.linked_columns,
            ]
          : undefined,
      };
      tempCols.push(baseCol);
      tempCols.push({
        ...col,
        name: `${col.name}___original`,
        is_unique: false,
      });

      if (col.type === 'autocomplete' && col.linked_columns?.length) {
        const colData = {
          ...col,
          linkedCols: [
            {source_column: col.source_column, name: col.name},
            ...col.linked_columns,
          ],
        };
        col.linked_columns.forEach((linkedCol) => {
          tempCols.push({
            ...colData,
            ...linkedCol,
            is_unique: false,
          });
          tempCols.push({
            ...colData,
            ...linkedCol,
            name: `${linkedCol.name}___original`,
            is_unique: false,
          });
        });
      }
    });

    if (seedData?.data?.length) {
      Object.keys(seedData.data[0]).forEach((key) => {
        if (!tempCols.find((col) => col.name === key)) {
          const newCol = {
            name: key,
            type: 'text',
            key,
            col_id: generateUniqueId([], 'int'),
            linked_columns: [],
            removed_col: true,
          };
          tempCols.push(newCol);
          tempCols.push({...newCol, name: `${key}___original`});
        }
      });
    }

    const tableCols = tempCols.map(getColOptions);
    setColumns(tableCols);
  }, [seedData, autocompleteOptions]);

  useEffect(() => {
    const params = new URLSearchParams();
    columns.forEach((col) => {
      if (
        col.type === 'autocomplete' &&
        col.source_table &&
        col.source_column
      ) {
        const sourceCols = params.get(col.source_table)?.split(',') || [];
        if (!sourceCols.includes(col.source_column)) {
          sourceCols.push(col.source_column);
        }
        col.linkedCols?.forEach((linkedCol) => {
          if (!sourceCols.includes(linkedCol.source_column)) {
            sourceCols.push(linkedCol.source_column);
          }
        });
        params.set(col.source_table, sourceCols.join(','));
      }
    });
    setAutocompleteCols(params.toString());
  }, [columns]);

  useEffect(() => {
    if (!seedData || !columns) return;
    if (seedData.message) {
      message.error({
        content: seedData.message,
        duration: 10,
        key: 'dataSourceError',
      });
      return;
    }
    const hot = hotRef?.current?.hotInstance;
    if (!hot) return;
    const newTableData = setupData();
    hot.updateSettings({
      data: newTableData.length ? newTableData : [[]],
      colHeaders: columns?.map((col) => col.title),
      columns,
      hiddenColumns: {
        columns: getHiddenColumns(),
        copyPasteEnabled: false,
      },
      afterChange: onAfterChange,
      afterGetColHeader: onAfterGetColHeader,
      // afterSetDataAtCell: onAfterChange,
      beforeAutofill: onBeforeAutofill,
      beforeChange: onBeforeChange,
      beforePaste: onBeforePaste,
      beforeRemoveRow: onBeforeRemoveRow,
    });
  }, [seedData, columns]);

  const onBeforePaste = (data, coords) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    // if the column is read only, don't allow pasting - display an error message
    if (coords[0].startCol === 0 || columns[coords[0].startCol].is_read_only) {
      message.error({
        content: 'Cannot paste to read only columns',
        duration: 2,
      });
      return false;
    }
    // first, set the table to loading so the user knows the data is being pasted
    setLoading(true);

    // we only want to paste to visible columns, so we need to match the columns to the data
    const colsToPaste = [];
    let index = coords[0].startCol;
    while (colsToPaste.length < data[0].length) {
      const col = columns[index];
      if (col.name && !col.name.includes('___')) {
        colsToPaste.push({
          ...col,
          index,
        });
      }
      index++;
    }

    // we want columns with data_type INTEGER to be converted to numbers
    const dataToPaste = data.map((row) =>
      row.map((val, i) => {
        const colDataType = colsToPaste[i]?.data_type;
        return colDataType === 'INTEGER' ? stringToFloat(val) : val;
      })
    );

    // if the data is only one row, we want to paste the same data to all the destination rows
    // if the data is multiple rows, we want to paste the data to the corresponding rows
    const newData = [];
    if (dataToPaste.length === 1) {
      const destRows = Array.from(
        {length: coords[0].endRow - coords[0].startRow + 1},
        (v, k) => k + coords[0].startRow
      );
      destRows.forEach((row) => {
        dataToPaste[0].forEach((val, i) => {
          newData.push([row, colsToPaste[i].index, val]);
        });
      });
    } else {
      const destRows = Array.from(
        {length: data.length},
        (v, k) => k + coords[0].startRow
      );
      dataToPaste.forEach((row, i) => {
        row.forEach((val, j) => {
          newData.push([destRows[i], colsToPaste[j]?.index, val]);
        });
      });
    }
    runActions([
      {
        method: 'setDataAtCell',
        data: newData,
      },
    ]);

    // set the table to not loading
    setLoading(false);
    return false;
  };

  const onBeforeAutofill = (
    selectionData,
    sourceRange,
    targetRange,
    direction
  ) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    setLoading(true);

    const autofillSourceCols = [];
    for (let i = sourceRange.from.col; i <= sourceRange.to.col; i++) {
      const colToProp = hot.colToProp(i);
      const col = columns.find((c) => c.name === colToProp);
      if (!col.name.includes('___')) {
        autofillSourceCols.push(col);
      }
    }
    // check if any of the cells in the source range are linked columns
    const autofillCols = [];
    for (const col of autofillSourceCols) {
      if (col.linkedCols?.length) {
        col.linkedCols.forEach((linkedCol) => {
          const col = columns.find((c) => c.name === linkedCol.name);
          if (col && autofillCols.indexOf(col) === -1) autofillCols.push(col);
        });
      } else {
        autofillCols.push(col);
      }
    }
    const rowsToUpdate = Array.from(
      {length: targetRange.to.row - targetRange.from.row + 1},
      (v, k) => k + targetRange.from.row
    );
    const dataToUpdate = [];
    for (const col of autofillCols) {
      // get the data from the source column
      const sourceColIdx = hot.propToCol(col.name);
      const sourceCellData = hot.getDataAtCell(
        sourceRange.from.row,
        sourceColIdx
      );
      // for each row in the target range, add an entry to the dataToUpdate array
      rowsToUpdate.forEach((row) => {
        dataToUpdate.push([row, sourceColIdx, sourceCellData]);
      });
    }
    runActions([
      {
        method: 'setDataAtCell',
        data: dataToUpdate,
      },
    ]);
    setLoading(false);
  };

  const handleClickTag = (item, row, prop) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    const oldData =
      hot.getDataAtRowProp(row, prop)?.toString().split(';').filter(Boolean) ||
      [];
    const data = oldData.includes(item)
      ? oldData.filter((val) => val !== item)
      : [...oldData, item];

    const col = hot.propToCol(prop);
    hot.setDataAtCell(row, col, data.join(';'));
    onAfterChange([[row, prop, oldData.join(';'), data.join(';')]], 'edit');
  };

  const addPrimaryKeys = (data, cols, keyName) => {
    return data.map((row) => {
      const newRow = columns.reduce((acc, col) => {
        acc[col.name] = row[col.name] ?? '';
        return acc;
      }, {});
      const uniqueKey =
        cols
          .map((col) => row[col] ?? '')
          .join('___')
          .replace(/_+/g, '') || undefined;
      newRow[keyName] = uniqueKey;
      return newRow;
    });
  };

  const setupData = useCallback(() => {
    if (!seedData) return [];
    const cols = getPrimaryKeyCols(seedData?.metadata?.primary_key, false);
    setPrimaryKeyCols(cols);

    const draftData = [
      ...(seedData.draft_data?.length
        ? seedData.draft_data
        : seedData.data ?? []),
    ];

    const draftDataWithPrimaryKeys = addPrimaryKeys(
      draftData,
      cols,
      '___primaryKey'
    );
    const originalData = addPrimaryKeys(
      [...(seedData.data ?? [])],
      cols,
      '___primaryKey'
    );

    const uniquePrimaryKeys = [
      ...new Set(
        draftDataWithPrimaryKeys
          .map((row) => row.___primaryKey)
          .concat(originalData.map((row) => row.___primaryKey))
      ),
    ];

    const newTableData = [];
    for (const uniqueKey of uniquePrimaryKeys) {
      const originalRow = originalData.filter(
        (row) => row.___primaryKey === uniqueKey
      );
      const draftRow = draftDataWithPrimaryKeys.filter(
        (row) => row.___primaryKey === uniqueKey
      );

      // create a new row with the original data and the draft data
      // if there is more than one row with the same primary key, add all the rows
      // unless the entire row is empty
      // numeric values are converted to numbers
      for (let i = 0; i < Math.max(originalRow.length, draftRow.length); i++) {
        const newRow = {};
        for (const col of columns) {
          if (col.name === '') continue;
          if (col.name.includes('___original')) {
            const dataCol = col.name.split('___')[0];
            newRow[col.name] = originalRow[i]?.[dataCol] ?? '';
          } else {
            newRow[col.name] = draftRow[i]?.[col.name] ?? '';
          }
          if (col.data_type === 'INTEGER') {
            newRow[col.name] = stringToFloat(newRow[col.name]);
          } else if (col.data_type === 'STRING') {
            newRow[col.name] = newRow[col.name]?.toString();
          }
        }
        if (Object.values(newRow).join('') !== '') {
          newTableData.push(newRow);
        }
      }
    }
    return newTableData;
  }, [columns, seedData]);

  const getPrimaryKeyCols = (primaryKey = [], useLinkedCols = true) => {
    const cols = [];
    for (const col of primaryKey) {
      const linkedCols = columns.find((c) => c.name === col)?.linkedCols;
      if (linkedCols?.length && useLinkedCols) {
        linkedCols.forEach((c) => c.name && cols.push(c.name));
      } else {
        cols.push(col);
      }
    }
    return cols;
  };

  const getColOptions = (col) => {
    const options = {
      ...col,
      data: col.name,
      title: col.title ?? col.name,
      allowEmpty: true,
      allowInvalid: true,
      renderer: safeHTMLRenderer,
      validator: textValidator,
      width: 200,
    };
    if (col.key === 'status') {
      options.renderer = iconRenderer;
      options.editor = false;
      options.readOnly = true;
      options.width = 55;
    } else if (col.type === 'dropdown') {
      options.strict = true;
      options.renderer = col.allow_multiple
        ? multiSelectRenderer
        : safeHTMLRenderer;
      options.validator = col.allow_multiple
        ? multiselectValidator
        : dropdownValidator;
      options.source = !col.source
        ? []
        : typeof col.source === 'string'
          ? JSON.parse(col?.source.replace(/'/g, '"') ?? '[]')
          : col.source;
      options.colOptions = options.source;
      options.onClickTag = handleClickTag;
    } else if (col.type === 'autocomplete') {
      options.strict = true;
      options.renderer = safeHTMLRenderer;
      options.editor = CustomAutocompleteEditor;
      options.validator = dropdownValidator;
      if (col.source_table) {
        const keys = col.linkedCols?.map((c) => c.source_column);
        options.allOptions = [
          ...new Set(
            // concatenate all the linked columns and remove duplicates
            autocompleteOptions?.[col.source_table]
              ?.map((row) =>
                keys?.length
                  ? keys
                      .reduce((acc, key) => `${acc}${row[key] ?? ''} | `, '')
                      .slice(0, -3)
                  : row[col.source_column]
              )
              ?.sort()
              ?.filter((val) => val)
          ),
        ];
        options.colOptions = [
          ...new Set(
            autocompleteOptions?.[col.source_table]
              ?.map((row) => row[col.source_column])
              ?.sort()
              ?.filter((val) => val)
          ),
        ];
        if (col.data_type === 'INTEGER') {
          options.colOptions = options.colOptions.map((val) =>
            stringToFloat(val)
          );
        }
        options.source = function (query, process) {
          if (!query) {
            const matches = options.allOptions.slice(0, 100);
            matches.unshift('');
            return process(matches);
          }

          // const queriesArray = query.toString().split(' ');
          // const minMatchCharLength = queriesArray.reduce(
          //   (acc, q) => (q.length < acc ? q.length : acc),
          //   1
          // );
          const fuse = new Fuse(options.allOptions, {
            keys: keys ?? [col.source_column],
            includeMatches: true,
            includeScore: true,
            threshold: 0.4,
            // ignoreLocation: true,
            // minMatchCharLength: minMatchCharLength,
            useExtendedSearch: true,
            // shouldSort: false,
            getFn: (obj) => {
              return obj.toString();
            },
          });

          const results = fuse.search(query);
          const matches = results.map((row) => row.item).slice(0, 100);
          matches.unshift('');
          return process(matches);
        };
      } else {
        options.source = [];
        options.colOptions = [];
      }
    }
    if (col.removed_col || col.is_read_only) {
      options.editor = false;
      options.readOnly = true;
    }
    return options;
  };

  const getHiddenColumns = useCallback(() => {
    return columns
      ?.map((col, i) => ({...col, index: i}))
      ?.filter((col) => col.name.indexOf('___') > -1)
      ?.map((col) => col.index);
  }, [columns]);

  const onAfterGetColHeader = (col, TH) => {
    const colData = columns[col];
    if (!colData || colData.name.includes('___')) return;

    const title = colData.removed_col
      ? 'Removed'
      : colData.type === 'dropdown'
        ? `${colData.allow_multiple ? 'Multiselect ' : ''}Options: \n${colData.source.join('\n')}`
        : colData.type === 'autocomplete'
          ? `Source: \n${colData.source_table}.${colData.source_column}${colData.allow_multiple ? '\nMultiselect' : ''}`
          : columnTypes.find((type) => type.value === colData.type)?.label;

    TH.setAttribute('title', title);
    if (colData?.removed_col) {
      TH.className = 'diff-removed-cell';
    }
    if (col === 0) {
      const button = TH.querySelector('.changeType');
      if (button) {
        button.style.display = 'none';
      }
    }
    return TH;
  };

  const onBeforeRemoveRow = (index, amount, physicalRows) => {
    // instead of removing the row, remove the data from visible columns
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    const allActions = [];

    for (let row of physicalRows) {
      for (let j = 0; j < hot.countCols(); j++) {
        const prop = hot.colToProp(j);
        if (prop.includes('___') || j === 0) continue;
        allActions.push([row, prop, '']);
      }
    }
    runActions([
      {
        method: 'setSourceDataAtCell',
        data: allActions,
      },
    ]);
    return false;
  };

  const onBeforeChange = (changes) => {
    if (!changes) return;
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    // if the data_type is INTEGER, convert the value to a number
    changes.forEach((change) => {
      const [row, prop, , newValue] = change;
      const col = hot.propToCol(prop);
      const cellMeta = hot.getCellMeta(row, col);

      change[3] =
        cellMeta.data_type === 'INTEGER'
          ? stringToFloat(newValue)
          : newValue?.toString().trim();
    });
  };

  const getRowStatuses = () => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;

    // set the button to loading
    // setCalculatingStatuses(true);
    const actions = [];

    clearFilters();
    const tableData = hot.getSourceData();

    const removed = [];
    const duplicates = {};
    const edited = {};
    const invalid = {};
    const blanks = {};
    const newRows = [];

    const uniqueColsVals = {};
    const uniqueCols = columns.filter(
      (col) => col.is_unique && !col.name.includes('___')
    );
    for (const col of uniqueCols) {
      const colVals = tableData.map((row) => {
        const val = col.linkedCols
          ? col.linkedCols.map((linkedCol) => row[linkedCol.name]).join('___')
          : row[col.name];
        return val;
      });
      uniqueColsVals[col.name] = colVals;
    }
    const primaryKeys = tableData.map((row) => row.___primaryKey);

    const newDataCols = columns.filter(
      (col) => !col.name.includes('___') && col.name !== ''
    );
    const originalDataCols = columns.filter((col) =>
      col.name.includes('___original')
    );

    function checkIfRowIsEdited(
      originalData,
      newData,
      i,
      statusValue,
      cellMeta
    ) {
      newDataCols.forEach((col, j) => {
        const originalVal = originalData[j];
        const newVal = newData[j];
        if (originalVal !== newVal) {
          if (!edited[i]) edited[i] = [];
          edited[i].push(col.name);
        }
      });
      if (edited[i]) statusValue.push('edited');
      if (edited[i]?.join('\n') !== cellMeta.edited) {
        actions.push({
          method: 'setCellMeta',
          row: i,
          col: 0,
          key: 'edited',
          value: 'Edited Values:\n' + edited[i].join('\n'),
        });
      }
    }

    function checkIfRowHasDuplicates(i, statusValue, cellMeta) {
      const primaryKey = primaryKeys[i];
      if (primaryKeys.filter((key) => key === primaryKey).length > 1) {
        if (!duplicates[i]) duplicates[i] = [];
        duplicates[i].push(primaryKeyCols.join(' + '));
      } else {
        const uniqueCols = Object.keys(uniqueColsVals);
        for (const col of uniqueCols) {
          const val = uniqueColsVals[col][i];
          if (uniqueColsVals[col].filter((v) => v === val).length > 1) {
            if (!duplicates[i]) duplicates[i] = [];
            const colName =
              columns
                .find((c) => c.name === col)
                ?.linkedCols?.map((c) => c.name)
                .join(' + ') ?? col;
            duplicates[i].push(colName);
            break;
          }
        }
      }
      if (duplicates[i]) statusValue.push('duplicate');
      if (duplicates[i] && duplicates[i].join('\n') !== cellMeta.duplicate) {
        actions.push({
          method: 'setCellMeta',
          row: i,
          col: 0,
          key: 'duplicate',
          value: 'Duplicate Columns:\n' + duplicates[i].join('\n'),
        });
      }
    }

    function checkIfRowHasInvalids(invalid, i, statusValue, cellMeta) {
      newDataCols.forEach((col) => {
        // only check cells that are not allowed to be invalid
        if (col.allow_invalid || col.name.includes('___') || col.name === '')
          return;
        let invalidMessage;
        // if the cell is a dropdown or autocomplete, the value must be in the source
        if (col.type === 'dropdown' || col.type === 'autocomplete') {
          invalidMessage = 'Value not in source';
        } else if (col.data_type === 'INTEGER') {
          invalidMessage = 'Value must be an integer';
        } else if (col.data_type === 'DATE') {
          invalidMessage = 'Value must be a date';
        } else {
          invalidMessage = 'Invalid Value';
        }

        !col.validator(tableData[i][col.name], (valid) => {
          if (!valid) {
            if (!invalid[i]) invalid[i] = [];
            invalid[i].push(col.name + ': ' + invalidMessage);
          }
        });
      });
      if (invalid[i]) statusValue.push('invalid');
      if (invalid[i] && invalid[i].join('\n') !== cellMeta.invalid) {
        actions.push({
          method: 'setCellMeta',
          row: i,
          col: 0,
          key: 'invalid',
          value: 'Invalid Values:\n' + invalid[i].join('\n'),
        });
      }
    }

    function checkIfRowHasBlanks(rowData, i, statusValue, cellMeta) {
      //
      newDataCols.forEach((col) => {
        // only check cells that are not allowed to be empty
        if (col.allow_empty || col.name.includes('___') || col.name === '')
          return;
        const blank = !rowData[col.name];
        if (blank) {
          if (!blanks[i]) blanks[i] = [];
          blanks[i].push(col.name);
        }
      });
      if (blanks[i]) statusValue.push('blank');
      if (blanks[i] && blanks[i].join('\n') !== cellMeta.blank) {
        actions.push({
          method: 'setCellMeta',
          row: i,
          col: 0,
          key: 'blank',
          value: 'Missing Values:\n' + blanks[i].join('\n'),
        });
      }
    }

    for (let i = 0; i < tableData.length; i++) {
      const rowData = tableData[i];
      const cellMeta = hot.getCellMeta(i, 0);
      const originalData = originalDataCols.map((col) => rowData[col.name]);
      const newData = newDataCols.map((col) => rowData[col.name]);

      const currentStatusValue = hot.getSourceDataAtCell(i, 0);
      const statusValue = [];

      if (newData.every((val) => !val)) {
        removed.push(i);
        for (const status of Object.keys(statuses)) {
          if (status === 'removed') {
            if (!cellMeta.removed) {
              actions.push({
                method: 'setCellMeta',
                row: i,
                col: 0,
                key: 'removed',
                value: 'Removed',
              });
            }
          } else if (cellMeta[status]) {
            actions.push({
              method: 'setCellMeta',
              row: i,
              col: 0,
              key: status,
              value: false,
            });
          }
        }
        statusValue.push('removed');
      } else {
        if (originalData.every((val) => !val)) {
          newRows.push(i);
          if (!cellMeta.newRow) {
            actions.push({
              method: 'setCellMeta',
              row: i,
              col: 0,
              key: 'newRow',
              value: 'New Row',
            });
          }
          statusValue.push('newRow');
        } else {
          // check if the row has been edited, and if so, which columns
          checkIfRowIsEdited(originalData, newData, i, statusValue, cellMeta);
        }

        // check if the row has blanks that are required
        checkIfRowHasBlanks(rowData, i, statusValue, cellMeta);

        // check if the row has invalid cells
        checkIfRowHasInvalids(invalid, i, statusValue, cellMeta);

        // check if the row has duplicate primary keys or unique columns
        checkIfRowHasDuplicates(i, statusValue, cellMeta);
      }

      if (statusValue.length && statusValue.join('\n') !== currentStatusValue) {
        actions.push({
          method: 'setDataAtCell',
          row: i,
          col: 0,
          value: statusValue.join('\n'),
        });
      }
    }

    if (actions.length) runActions(actions);

    const rowStatuses = {
      removed: removed.length,
      duplicate: Object.keys(duplicates).length,
      edited: Object.keys(edited).length,
      invalid: Object.keys(invalid).length,
      blank: Object.keys(blanks).length,
      newRow: newRows.length,
    };

    setStatusCounts(rowStatuses);
    // setCalculatingStatuses(false);
  };

  const runActions = (allActions) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot || !allActions?.length) return;

    // consolidate all setDataAtCell actions into one
    const setDataAtCellActions = allActions.filter(
      (action) => action.method === 'setDataAtCell'
    );
    const otherActions = allActions.filter(
      (action) => action.method !== 'setDataAtCell'
    );

    const allSetDataActions = [];
    // if data that is part of the primary key is changed, recalculate the primary key
    const rowsToRecalculatePrimaryKey = new Set();
    for (const action of setDataAtCellActions) {
      if (Array.isArray(action.data)) {
        action.data.forEach((data) => {
          const [row, col, value] = data;
          const prop = hot.colToProp(col);
          if (primaryKeyCols.includes(prop)) {
            rowsToRecalculatePrimaryKey.add(row);
          }
          allSetDataActions.push([row, col, value]);
        });
      } else {
        const {row, col, value} = action;
        allSetDataActions.push([row, col, value]);
        const prop = hot.colToProp(col);
        if (primaryKeyCols.includes(prop)) {
          rowsToRecalculatePrimaryKey.add(row);
        }
      }
    }
    if (allSetDataActions.length) {
      otherActions.push({
        method: 'setDataAtCell',
        data: allSetDataActions,
      });
    }

    hot.batch(() => {
      otherActions.forEach((action) => {
        switch (action.method) {
          case 'validateCell':
            hot.validateCell(action.data, action.cellMeta, (valid) => {});
            break;
          case 'setCellMeta':
            hot.setCellMeta(action.row, action.col, action.key, action.value);
            break;
          case 'setDataAtCell':
            if (Array.isArray(action.data)) {
              hot.setDataAtCell(action.data);
            } else {
              hot.setDataAtCell(action.row, action.col, action.value);
            }
            break;
          case 'setSourceDataAtCell':
            if (Array.isArray(action.data)) {
              hot.setSourceDataAtCell(action.data);
            } else {
              hot.setSourceDataAtCell(action.row, action.col, action.value);
            }
            break;
          default:
            break;
        }
      });
      hot.render();
    });
    const newPrimaryKeys = [];
    const primaryKeyColIdx = hot.propToCol('___primaryKey');
    rowsToRecalculatePrimaryKey.forEach((row) => {
      let newPrimaryKey = primaryKeyCols
        .map((col) => hot.getDataAtCell(row, hot.propToCol(col)))
        .join('___');
      if (newPrimaryKey.replaceAll('_', '') === '') {
        newPrimaryKey = undefined;
      }
      newPrimaryKeys.push([row, primaryKeyColIdx, newPrimaryKey]);
    });
    hot.setDataAtCell(newPrimaryKeys);
  };

  const setDataFromSource = () => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;

    setLoading(true);
    const source = seedData?.metadata?.autoload_from;
    const autoloadPrimaryKey = seedData?.metadata?.autoload_primary_key;
    const rows = autocompleteOptions?.[source];

    if (!rows || !rows.length) {
      message.error({
        content: !rows?.length
          ? 'No data found in the source table'
          : 'Primary key not found',
        key: 'autoload',
      });
      setLoading(false);
      return;
    }
    filterByStatus('all');

    const autoloadPrimaryKeyCols = getPrimaryKeyCols([autoloadPrimaryKey]);
    const keyCols = autoloadPrimaryKeyCols.map((col) =>
      columns.find((c) => c.name === col)
    );

    // TODO: check if the source_column name is different from the column name to avoid unnecessary renaming
    const newDataWithSourceColumnRenamed = rows.map((row) => {
      const newRow = {};
      for (const col of keyCols) {
        newRow[col.name] = row[col.source_column];
      }
      return newRow;
    });

    const data = hot.getSourceData();

    const hotDataWithAutoloadPrimaryKeys = addPrimaryKeys(
      data,
      autoloadPrimaryKeyCols,
      '___autoloadPrimaryKey'
    );
    const newDataWithAutoloadPrimaryKeys = addPrimaryKeys(
      newDataWithSourceColumnRenamed,
      autoloadPrimaryKeyCols,
      '___autoloadPrimaryKey'
    );

    const newTableData = [];
    for (const row of newDataWithAutoloadPrimaryKeys) {
      const newRow = {};
      const match = hotDataWithAutoloadPrimaryKeys.find(
        (r) => r.___autoloadPrimaryKey === row.___autoloadPrimaryKey
      );
      for (const col of columns) {
        if (!col.name.includes('___') && col.name !== '') {
          newRow[col.name] = match ? match[col.name] : row[col.name];
        } else {
          newRow[col.name] = match ? match[col.name] : '';
        }
      }
      newTableData.push(newRow);
    }

    const newTableDataWithPrimaryKeys = addPrimaryKeys(
      newTableData,
      primaryKeyCols,
      '___primaryKey'
    );

    // add old data that is not in the new data to the new data
    for (const row of hotDataWithAutoloadPrimaryKeys) {
      if (
        !newDataWithAutoloadPrimaryKeys.find(
          (r) => r.___autoloadPrimaryKey === row.___autoloadPrimaryKey
        )
      ) {
        newTableDataWithPrimaryKeys.push(row);
      }
    }

    hot.batch(() => {
      hot.updateSettings({
        data: newTableDataWithPrimaryKeys,
      });
    });
    setIsEdited(true);
    setTimeout(() => setLoading(false), 1000);
  };

  const filterByStatus = (status) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;

    setSelectedStatus(status);
    const filtersPlugin = hot.getPlugin('filters');
    filtersPlugin.clearConditions(0);
    if (status !== 'all') {
      filtersPlugin.addCondition(0, 'contains', [status]);
    }
    filtersPlugin.filter();
  };

  const handleSave = (version) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    if (!seedData || loadingData || !hot || !hot.getSourceData()?.length)
      return;
    const data = hot.getSourceData();
    const dataToSave = [];
    const colsToSave = [];
    seedData?.metadata.columns?.forEach((col) => {
      colsToSave.push(col.name);
      if (col.type === 'autocomplete' && col.linked_columns?.length) {
        col.linked_columns.forEach((linkedCol) => {
          colsToSave.push(linkedCol.name);
        });
      }
    });

    if (!colsToSave) {
      message.error({
        content: 'Please save the configuration for this seed first',
        key: 'save',
      });
      return;
    }
    data?.forEach((row) => {
      const newRow = {};
      for (const col of colsToSave) {
        if (version === 'discard') {
          newRow[col] = row[`${col}___original`];
        } else {
          newRow[col] = row[col];
        }
      }
      // if the row values are all empty or undefined, don't save the row
      if (Object.values(newRow).join('') !== '') {
        dataToSave.push(newRow);
      }
    });

    const originalData =
      version === 'final' ? seedData?.data : seedData?.draft_data;
    // check if dataToSave is different from the original data
    if (_.isEqual(dataToSave, originalData)) {
      message.info({
        content: `Nothing to save`,
        key: 'save',
        duration: 2,
      });
      return;
    }
    filterByStatus('all');

    const newData = {
      seed_name: `${seedId}${version === 'final' ? '' : '_draft'}`,
      data: dataToSave,
    };
    saveSeedData(newData)
      .unwrap()
      .then(() => {
        if (!hot) return;
        filterByStatus('all');
        message.success({
          content: version === 'final' ? 'Saved' : 'Draft saved',
          key: 'save',
          duration: 2,
        });
      });
    if (version !== 'final') setIsEdited(false);
  };

  const clearFilters = () => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    setSelectedStatus('all');
    const filtersPlugin = hot.getPlugin('filters');
    const sortPlugin = hot.getPlugin('columnSorting');
    sortPlugin.clearSort();
    filtersPlugin.clearConditions();
    filtersPlugin.filter();
  };

  const onAfterChange = (changes, source) => {
    if (changes) setIsEdited(true);
    if (changes?.[0]?.[1] === '') return;
    // get the status of the cells after the changes
  };

  return (
    <div>
      <Alert
        description={
          <div>
            <p>How to use this page:</p>
            <ul>
              <li>Double click or start typing to open a cell dropdown.</li>
              {seedData?.metadata?.enable_autoload && (
                <li>
                  Click 'Load New Entries' to load any new entries from the
                  source table. Entries that already appear in the table will
                  not be duplicated or overwritten.
                </li>
              )}
              <li>Click 'Check Statuses' to review data before saving.</li>
              <li>Hit "Save Draft" before clicking "Finalize Changes".</li>
            </ul>
          </div>
        }
        type="success"
        style={{marginBottom: '20px'}}
      />
      {seedData?.metadata?.enable_autoload && (
        <Button
          onClick={setDataFromSource}
          disabled={loadingData || loadingOptions || loading}
          type="primary"
          style={{marginBottom: '20px'}}
        >
          Load New Entries
        </Button>
      )}
      <Spin spinning={loadingData || loadingOptions || loading}>
        <Segmented
          options={[
            {label: 'All', value: 'all'},
            ...Object.keys(statusCounts).map((status) => ({
              label: `${statuses[status]?.label} (${statusCounts[status]})`,
              value: status,
              disabled: statusCounts[status] === 0,
            })),
          ]}
          defaultValue="all"
          value={selectedStatus}
          onChange={filterByStatus}
        />
        <Button
          onClick={getRowStatuses}
          type="primary"
          // loading={calculatingStatuses}
        >
          Check Statuses
        </Button>
        <Button onClick={clearFilters} type="primary" style={{float: 'right'}}>
          Clear Filters
        </Button>
        <div style={{height: '75vh', marginTop: '5px'}}>
          <HotTable ref={hotRef} settings={settings} />
        </div>
      </Spin>
      <div className="flex-row" style={{margin: '10px 0'}}>
        <Button
          type="primary"
          onClick={() => handleSave('draft')}
          disabled={loadingData || !isEdited}
        >
          Save Draft
        </Button>
        <Popconfirm
          title={
            <div>
              Are you sure you want to discard this draft?
              <br />
              This cannot be undone.
            </div>
          }
          disabled={loadingData || !isEdited}
          onConfirm={() => handleSave('discard')}
          okText="Yes"
          cancelText="No"
          okButtonProps={{danger: true, type: 'default'}}
          cancelButtonProps={{type: 'primary'}}
        >
          <Button disabled={loadingData || !isEdited}>Discard Draft</Button>
        </Popconfirm>
        <span style={{flex: 1}} />
        <Popconfirm
          title={
            <div>
              Are you sure you want to save this version?
              <br />
              This cannot be undone.
            </div>
          }
          disabled={
            loadingData ||
            !seedData?.draft_data?.length ||
            !seedData?.metadata?.published
          }
          onConfirm={() => handleSave('final')}
          okText="Yes"
          cancelText="No"
          okButtonProps={{danger: true, type: 'default'}}
          cancelButtonProps={{type: 'primary'}}
        >
          <Button
            disabled={
              loadingData ||
              !seedData?.draft_data?.length ||
              !seedData?.metadata?.published
            }
          >
            Finalize Changes
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export default TableEditor;
