import {MailOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Input, Modal, Table} from 'antd';
import {useFetchSchoolAuditsQuery} from 'api/auditsSlice';
import StudentDetails from 'components/audits/StudentDetails';
import {FIX_STATUS_DISPLAY} from 'consts';
import React, {useEffect, useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {useLocation, useNavigate} from 'react-router-dom';

const AuditsTable = ({siteId}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [fixingStudent, setFixingStudent] = useState(false);
  const [fixModalVisible, setFixModalVisible] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [fieldAuditId, setFieldAuditId] = useState();

  const {data, isLoading} = useFetchSchoolAuditsQuery(siteId);

  useEffect(() => {
    // get the query params from the url
    const params = new URLSearchParams(location.search);

    // set the filters and sorting
    const sortColumn = params.get('sortColumn');
    const sortOrder = params.get('sortOrder');
    if (sortColumn && sortOrder) {
      setSortedInfo({
        order: sortOrder,
        field: sortColumn,
      });
    }
    const filters = {};
    for (const [key, value] of params.entries()) {
      if (key.startsWith('filter_')) {
        filters[key.substring(7)] = [value];
      }
    }
    setFilteredInfo(filters);

    // set the fixing student
    const fixingStudent = params.get('fixingStudent');
    if (fixingStudent) {
      setFixingStudent(fixingStudent);
    }
  }, []);

  const searchInput = useRef();

  const showModal = () => {
    setFixModalVisible(true);
    setSearchParams(true);
  };

  const handleClose = () => {
    setFixModalVisible(false);
    setSearchParams(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <div className="flex-row">
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Filter
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}
          >
            Clear
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const setSearchParams = (modalVisible) => {
    let search = '?';

    if (sortedInfo.columnKey && sortedInfo.order) {
      search +=
        'sortColumn=' + sortedInfo.columnKey + '&sortOrder=' + sortedInfo.order;
    }

    for (const field in filteredInfo) {
      const f = filteredInfo[field];

      if (f) {
        for (const entry in f) {
          search += `&filter_${field}=${f[entry]}`;
        }
      }
    }

    if (modalVisible) {
      search += `&fixingStudent=${fixingStudent}`;
    }

    navigate(search);
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const setupTableData = (dataSource) => {
    dataSource = dataSource.map((e) => {
      const has_comment = e.comment ? 'Yes' : 'No';
      const field_value_or_none = e.field_value ? e.field_value : '[None]';
      let fix_status_display = FIX_STATUS_DISPLAY[e.fix_status]; // Failed after fix isn't considered a status on the BE, so we need to make it
      // a status here.
      // We'll only consider it "failed after fix" if the status is "fixed", because if not,
      // someone could've marked it "Active".

      if (e.failed_after_fix && e.fix_status === 'fixed') {
        fix_status_display = FIX_STATUS_DISPLAY['failed_after_fix'];
      }

      let errors = e.error_description.map((d) => {
        return <li key={d}>{d}</li>;
      });
      let details = (
        <>
          <div>
            Errors:
            <ul>{errors}</ul>
          </div>
          <div>
            Comment:{' '}
            {e.comment ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: e.comment.comment,
                }}
              ></p>
            ) : (
              'None'
            )}
          </div>
        </>
      );
      e = {
        has_comment,
        details,
        field_value_or_none,
        fix_status_display,
        ...e,
      };
      return e;
    });
    return dataSource;
  };

  const getFieldsForFilter = () => {
    if (!data) return [];
    const uniqueFields = [...new Set(data.map((e) => e.field))];
    return uniqueFields.map((e) => ({
      text: e,
      value: e,
    }));
  };

  const getErrorTypesForFilter = () => {
    if (!data) return [];
    let uniqueErrorTypes = [...new Set(data.map((e) => e.error_type))];
    return uniqueErrorTypes.map((e) => ({
      text: e,
      value: e,
    }));
  };

  const getFixStatusesForFilter = () => {
    if (!data) return [];
    return [
      {
        text: 'Active',
        value: 'Active',
      },
      {
        text: 'Fixed',
        value: 'Fixed',
      },
      {
        text: "Can't Fix",
        value: "Can't Fix",
      },
      {
        text: 'Failed after fix',
        value: 'Failed after fix',
      },
    ];
  };

  const columns = [
    {
      title: 'Error Type',
      dataIndex: 'error_type',
      key: 'error_type',
      width: 150,
      sorter: (a, b) => a.error_type.localeCompare(b.error_type),
      sortOrder: sortedInfo.columnKey === 'error_type' && sortedInfo.order,
      filters: getErrorTypesForFilter(),
      filteredValue: filteredInfo.error_type || null,
      onFilter: (value, record) => record.error_type.indexOf(value) === 0,
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      width: 250,
      sorter: (a, b) => a.field.localeCompare(b.field),
      sortOrder: sortedInfo.columnKey === 'field' && sortedInfo.order,
      filters: getFieldsForFilter(),
      filteredValue: filteredInfo.field || null,
      onFilter: (value, record) => record.field.indexOf(value) === 0,
    },
    {
      title: 'Student Name',
      dataIndex: 'student_name',
      key: 'student_name',
      width: 150,
      sorter: (a, b) => a.student_name.localeCompare(b.student_name),
      sortOrder: sortedInfo.columnKey === 'student_name' && sortedInfo.order,
      filteredValue: filteredInfo.student_name || null,
      ...getColumnSearchProps('student_name'),
    },
    {
      title: 'Student Number',
      dataIndex: 'student_number',
      key: 'student_number',
      width: 150,
      align: 'right',
      sorter: (a, b) => a.student_number - b.student_number,
      sortOrder: sortedInfo.columnKey === 'student_number' && sortedInfo.order,
      filteredValue: filteredInfo.student_number || null,
      ...getColumnSearchProps('student_number'),
    },
    {
      title: 'Current Value',
      dataIndex: 'field_value_or_none',
      key: 'field_value_or_none',
      width: 150,
      align: 'right',
      filteredValue: filteredInfo.field_value_or_none || null,
      ...getColumnSearchProps('field_value_or_none'),
    },
    {
      title: 'Fix Status',
      dataIndex: 'fix_status_display',
      key: 'fix_status_display',
      width: 150,
      align: 'right',
      sorter: (a, b) =>
        a.fix_status_display.localeCompare(b.fix_status_display),
      sortOrder:
        sortedInfo.columnKey === 'fix_status_display' && sortedInfo.order,
      filters: getFixStatusesForFilter(),
      filteredValue: filteredInfo.fix_status_display || null,
      onFilter: (value, record) =>
        record.fix_status_display.indexOf(value) === 0,
    },
    {
      title: 'Comment?',
      dataIndex: 'has_comment',
      key: 'has_comment',
      width: 100,
      align: 'right',
      render: (text) => (
        <>
          {text === 'Yes' && <MailOutlined />}
          {text === 'No' && <></>}
        </>
      ),
      sorter: (a, b) => a.has_comment - b.has_comment,
      sortOrder: sortedInfo.columnKey === 'has_comment' && sortedInfo.order,
      filters: [
        {
          text: 'Yes',
          value: 'Yes',
        },
        {
          text: 'No',
          value: 'No',
        },
      ],
      filteredValue: filteredInfo.has_comment || null,
      onFilter: (value, record) => record.has_comment === value,
    },
    {
      title: '',
      dataIndex: 'student_number',
      key: 'action',
      width: 120,
      render: (student_number, record) => (
        <Button
          type="primary"
          onClick={() => {
            setFixingStudent(student_number);
            setFieldAuditId(record.field_audit_id);
            showModal();
          }}
        >
          Fix Student
        </Button>
      ),
    },
  ];

  return (
    <div>
      {data && (
        <p>
          <strong>Total audits in school:&nbsp;</strong>
          {data.length}
        </p>
      )}

      <Table
        dataSource={setupTableData(data || [])}
        columns={columns}
        key={data}
        size="small"
        scroll={{
          x: '100%',
          y: '80vh',
        }}
        pagination={{
          position: ['bottomRight'],
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div
              style={{
                margin: 0,
              }}
            >
              {record.details}
            </div>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        rowKey="field_audit_id"
        loading={isLoading}
        onChange={handleTableChange}
        bordered
        title={(currentPageData) => {
          return <div>Currently displayed: {currentPageData.length}</div>;
        }}
      />

      {/* Student Details Modal Overlay */}
      <Modal
        title="Fix Student"
        open={fixModalVisible}
        width={1000}
        onOk={handleClose}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={handleClose}>
            Close
          </Button>,
        ]}
      >
        <StudentDetails
          key={fixingStudent + fieldAuditId}
          studentId={fixingStudent}
          fieldAuditId={fieldAuditId}
        />
      </Modal>
    </div>
  );
};

export default AuditsTable;
