import {Select} from 'antd';
import React, {useEffect, useState} from 'react';

const {Option} = Select;

const SchoolDropdown = ({
  onSchoolSelectChange,
  chosenSchool,
  schools,
  style = {},
  filters,
}) => {
  const [filteredSchools, setFilteredSchools] = useState(schools);
  const onChangeSelection = (o) => {
    const school = filteredSchools.find((v) => v.site_id === o);
    onSchoolSelectChange(school);
  };

  useEffect(() => {
    if (filters) {
      const filtered = schools.filter((v) =>
        Object.entries(filters).every(([key, value]) => value.includes(v[key]))
      );
      setFilteredSchools(filtered);
    }
  }, [filters, schools]);

  return (
    <Select
      showSearch
      style={{width: 600, maxWidth: '100%', ...style}}
      placeholder="Select a school"
      optionFilterProp="label"
      onChange={onChangeSelection}
      value={chosenSchool?.site_id}
    >
      {filteredSchools?.length ? (
        filteredSchools.map((v, index) => (
          <Option
            value={v.site_id}
            key={v.abbreviation}
            label={v.abbreviation + v.school_name}
          >
            <strong>{v.abbreviation}</strong> - {v.school_name}
          </Option>
        ))
      ) : (
        <Option value={0} disabled>
          Loading schools...
        </Option>
      )}
    </Select>
  );
};

export default SchoolDropdown;
