import Auth from 'Auth';
import {Button, Divider, Spin, Tooltip} from 'antd';
import {useFetchAggregateReportsListQuery} from 'api/reportsSlice';
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

const DataReportOverviewPage = () => {
  const location = useLocation();
  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.accessToDataReportsEditor();

  const [reportsToDisplay, setReportsToDisplay] = useState([]);

  const {data: reports, isLoading} = useFetchAggregateReportsListQuery();

  useEffect(() => {
    if (reports) {
      setReportsToDisplay(
        editorMode ? reports.reportsByCategory : reports.savedReports
      );
    }
  }, [reports, editorMode]);

  return (
    <div>
      <h1>Data Reports</h1>
      <div
        style={{
          padding: '20px',
        }}
      >
        <Spin spinning={isLoading}>
          {reports && Object.keys(reportsToDisplay).length ? (
            Object.keys(reportsToDisplay).map((folder) => (
              <div key={folder}>
                <h3>{folder}</h3>
                {reportsToDisplay[folder]?.map((report) => (
                  <div key={report.id}>
                    <Tooltip
                      title={report.long_description}
                      placement="bottomLeft"
                    >
                      <Link
                        to={`/data_report/${report.id}${editorMode ? '/editor' : ''}`}
                        style={{fontSize: '16px'}}
                      >
                        {report.report_name}
                      </Link>
                    </Tooltip>
                    <p>{report.short_description}</p>
                  </div>
                ))}
                <Divider />
              </div>
            ))
          ) : (
            <div>There are no saved reports yet.</div>
          )}
        </Spin>
        {editorMode && (
          <Button type="primary" href="/data_report/new/editor">
            Create New Report
          </Button>
        )}
      </div>
    </div>
  );
};

export default DataReportOverviewPage;
