import {Table} from 'antd';
import {
  useFetchAuditErrorRateByFieldQuery,
  useFetchSchoolAuditRulesQuery,
} from 'api/auditsSlice';
import React from 'react';
import {Link} from 'react-router-dom';

const AuditsIssueOverviewTable = ({siteId, chosenRegion}) => {
  const {data: rules, isLoading: rulesLoading} =
    useFetchSchoolAuditRulesQuery(siteId);

  const {data, isLoading: errorRateLoading} =
    useFetchAuditErrorRateByFieldQuery(siteId);

  const getDataForTable = () => {
    if (!data) {
      return [];
    }
    let filteredData = [...data.errorRate];

    if (chosenRegion !== 'All') {
      filteredData = filteredData.filter((e) => e.region === chosenRegion);
    }

    return transformRawDataToTable(filteredData);
  };

  const getAuditFieldDescriptions = (field) => {
    return [...rules]
      .filter((e) => e.field === field)
      .map((e) => e.error_description);
  };

  const transformRawDataToTable = (filteredData) => {
    const error_types = [...new Set(filteredData.map((e) => e.error_type))];
    const fieldSummaryTable = {};

    filteredData.forEach((data) => {
      const {field, errors, school} = data;

      if (errors !== 0) {
        if (!fieldSummaryTable[field]) {
          fieldSummaryTable[field] = {
            num_schools: 0,
            num_errors: 0,
            schools: [],
          };
        }

        fieldSummaryTable[field].num_schools++;
        fieldSummaryTable[field].num_errors += errors;
        fieldSummaryTable[field].schools.push({school, errors});
      }
    });

    const fullSummaryTable = setupSummary(
      error_types,
      filteredData,
      fieldSummaryTable
    );

    return fullSummaryTable;
  };

  const setupSummary = (error_types, filteredData, fieldSummaryTable) => {
    const fullSummaryTable = [];
    let index = 0;
    error_types.forEach((error_type) => {
      const fields = [
        ...new Set(filteredData.filter((e) => e.error_type === error_type)),
      ];

      const total_num_schools = fields.filter((e) => e.errors > 0).length;

      let total_num_errors = 0;
      const fields_list = [...new Set(fields.map((e) => e.field))];
      const rowsToAdd = fields_list.map((field) => {
        // This displays all the fields, and the schools that have those errors
        // If the field has no errors, it *wil* be displayed, maybe in the future we can remove those
        const num_schools = fieldSummaryTable?.[field]?.num_schools || 0;
        const num_errors = fieldSummaryTable?.[field]?.num_errors || 0;
        const schools =
          fieldSummaryTable?.[field]?.schools ||
          [].sort((a, b) => a.school.localeCompare(b.school));

        total_num_errors += num_errors;

        return {
          index: index++,
          is_header: false,
          issue_type: null,
          field,
          num_schools,
          num_errors,
          schools,
        };
      });

      const error_type_header_row = {
        index: index++,
        is_header: true,
        issue_type: error_type,
        field: null,
        num_schools: total_num_schools,
        num_errors: total_num_errors,
      };

      fullSummaryTable.push(error_type_header_row, ...rowsToAdd);
    });

    return fullSummaryTable;
  };

  const columns = [
    {
      title: 'Issue Type',
      dataIndex: 'issue_type',
      key: 'issue_type',
      width: 200,
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: '# Schools',
      dataIndex: 'num_schools',
      key: 'num_schools',
      align: 'right',
    },
    {
      title: '# Errors',
      dataIndex: 'num_errors',
      key: 'num_errors',
      align: 'right',
    },
  ];

  return (
    <div style={{marginTop: '10px'}} className="audits-by-issue-table">
      <Table
        dataSource={getDataForTable()}
        columns={columns}
        rowClassName={(record, index) =>
          record.is_header ? 'table-row-header' : ''
        }
        expandable={{
          expandedRowRender: (record) => {
            return (
              <>
                <div
                  style={{
                    marginLeft: '250px',
                    fontWeight: 'normal',
                  }}
                >
                  <strong>Rule errors:</strong>
                  <ul>
                    {getAuditFieldDescriptions(record.field).map((desc) => (
                      <li key={desc}>{desc}</li>
                    ))}
                  </ul>
                </div>
                <br />
                {record['schools']
                  ?.sort((a, b) => a.school?.localeCompare(b.school))
                  .map((school_record, index) => (
                    <div
                      style={{
                        marginLeft: '250px',
                      }}
                      key={index}
                    >
                      <Link
                        to={`/audits/${school_record.school}/?filter_field=${record.field}`}
                      >
                        {school_record.school}
                      </Link>
                      : &nbsp;&nbsp;&nbsp;
                      {school_record.errors} error(s)
                    </div>
                  ))}
              </>
            );
          },
          rowExpandable: (record) => !record.is_header,
        }}
        pagination={false}
        size="small"
        loading={errorRateLoading || rulesLoading}
        rowKey="index"
      />
    </div>
  );
};
export default AuditsIssueOverviewTable;
