import {LoadingOutlined, UndoOutlined} from '@ant-design/icons';
import {Button, Col, Row, Spin, Tooltip, message} from 'antd';
import {
  useFetchPersonDetailsQuery,
  useSetPersonDetailsMutation,
} from 'api/apiSlice';
import EditableField from 'components/genericComponents/EditableField';
import {handleApiError} from 'errorHandler';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

const PersonDetailsCardPrimary = styled.div`
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  font-size: 18px;
  background: #ffdd00;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PersonDetailsCardAlt = styled.div`
  padding: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  background: #eee;
`;

const UCPersonDetails = ({ucpid}) => {
  const [editPersonDetails, setEditPersonDetails] = useState({});

  const {data: initialPersonDetails, isLoading} =
    useFetchPersonDetailsQuery(ucpid);

  const [setPersonDetails, {isLoading: isSaving}] =
    useSetPersonDetailsMutation();

  useEffect(() => {
    if (!initialPersonDetails) return;
    setEditPersonDetails({
      first_name: initialPersonDetails.uc_person.first_name,
      last_name: initialPersonDetails.uc_person.last_name,
      email: initialPersonDetails.uc_person.email,
      phone: initialPersonDetails.uc_person.phone,
    });
  }, [initialPersonDetails]);

  const handleFieldChange = (fieldName, newValue) => {
    setEditPersonDetails({
      ...editPersonDetails,
      [fieldName]: newValue,
    });
  };

  const savePerson = () => {
    const key = 'save';
    message.loading({
      content: 'Saving changes to person...',
      key,
      duration: 0,
    });

    setPersonDetails({
      ucpid: initialPersonDetails.uc_person.ucpid,
      details: editPersonDetails,
    })
      .then((response) => {
        message.success({content: 'Saved!', key, duration: 2});
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const getEditedFields = () => {
    let editedFields = [];
    for (let field in editPersonDetails) {
      if (editPersonDetails[field] !== initialPersonDetails.uc_person[field]) {
        editedFields.push(field);
      }
    }
    return editedFields;
  };

  const personEdited = () => {
    let editedFields = getEditedFields();
    return editedFields.length > 0;
  };

  const hasFieldChanged = (fieldName) => {
    let editedFields = getEditedFields();
    return editedFields.includes(fieldName);
  };

  return (
    <Spin spinning={isLoading}>
      {initialPersonDetails && (
        <>
          <PersonDetailsCardPrimary>
            <Row>
              <Col span={24}>
                <h3>UCPID</h3>
                <p>{initialPersonDetails['uc_person']?.ucpid}</p>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={12}>
                <EditableField
                  key={editPersonDetails.first_name}
                  label="First Name"
                  value={editPersonDetails.first_name}
                  onChange={(newValue) =>
                    handleFieldChange('first_name', newValue)
                  }
                  hasChanged={hasFieldChanged('first_name')}
                />
              </Col>
              <Col span={12}>
                <EditableField
                  key={editPersonDetails.last_name}
                  label="Last Name"
                  value={editPersonDetails.last_name}
                  onChange={(newValue) =>
                    handleFieldChange('last_name', newValue)
                  }
                  hasChanged={hasFieldChanged('last_name')}
                  placeholder={'[empty]'}
                />
              </Col>
            </Row>
            <Row gutter={[0, 32]}>
              <Col span={12}>
                <EditableField
                  key={editPersonDetails.email}
                  label="Email"
                  value={editPersonDetails.email}
                  onChange={(newValue) => handleFieldChange('email', newValue)}
                  hasChanged={hasFieldChanged('email')}
                  placeholder={'[empty]'}
                />
              </Col>
              <Col span={12}>
                <EditableField
                  key={editPersonDetails.phone}
                  label="Phone"
                  value={editPersonDetails.phone}
                  onChange={(newValue) => handleFieldChange('phone', newValue)}
                  hasChanged={hasFieldChanged('phone')}
                  placeholder={'[empty]'}
                />
              </Col>
            </Row>
            <Row>
              <Button onClick={savePerson} disabled={!personEdited()}>
                {isSaving ? <LoadingOutlined /> : 'Save Changes'}
              </Button>
            </Row>
          </PersonDetailsCardPrimary>

          <div>
            <h2>System Account Details</h2>
            {initialPersonDetails.source_systems.map((source_system, index) => (
              <PersonDetailsCardAlt key={index}>
                <Row>
                  <Col span={12}>
                    <h3>Source System UCPID</h3>
                    <p>{source_system.ucpid}</p>
                  </Col>
                  <Col span={12}>
                    <h3>Source System ID</h3>
                    <p>{source_system.source_system_id}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <h3>First Name</h3>
                    <span>
                      <div className="flex-row">
                        {source_system.first_name}

                        <Tooltip title="Copy value into UC Person">
                          <UndoOutlined
                            onClick={() =>
                              handleFieldChange(
                                'first_name',
                                source_system.first_name
                              )
                            }
                          />
                        </Tooltip>
                      </div>
                    </span>
                  </Col>
                  <Col span={12}>
                    <h3>Last Name</h3>
                    <span>
                      <div className="flex-row">
                        {source_system.last_name}

                        <Tooltip title="Copy value into UC Person">
                          <UndoOutlined
                            onClick={() =>
                              handleFieldChange(
                                'last_name',
                                source_system.last_name
                              )
                            }
                          />
                        </Tooltip>
                      </div>
                    </span>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={12}>
                    <h3>Email</h3>
                    <div className="flex-row">
                      {source_system.email}

                      <Tooltip title="Copy value into UC Person">
                        <UndoOutlined
                          onClick={() =>
                            handleFieldChange('email', source_system.email)
                          }
                        />
                      </Tooltip>
                    </div>
                  </Col>
                  <Col span={12}>
                    <h3>Phone</h3>
                    <div className="flex-row">
                      {source_system.phone ? (
                        <>
                          {source_system.phone}
                          <Tooltip title="Copy value into UC Person">
                            <UndoOutlined
                              onClick={() =>
                                handleFieldChange(
                                  'phone',
                                  source_system.phone ? source_system.phone : ''
                                )
                              }
                            />
                          </Tooltip>
                        </>
                      ) : (
                        <>[empty]</>
                      )}
                    </div>
                  </Col>
                </Row>
              </PersonDetailsCardAlt>
            ))}
          </div>
        </>
      )}
    </Spin>
  );
};

export default UCPersonDetails;
