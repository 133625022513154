import {emptySplitApi} from 'api/apiSlice';
import {handleRTKQError} from 'errorHandler';

const transformSnapshotResponse = (response, field) => {
  const newList = [];
  const fields = response.map((e) => e[field]) || [];
  fields.forEach((f) => {
    if (newList.find((e) => e.id === f)) {
      return;
    }
    const o = {
      id: f,
    };
    const schoolEntries = response.filter((c) => c[field] === f);
    o.data = schoolEntries.map((et) => ({
      x: et.updated_at,
      y: et.errors,
    }));
    o.data.sort((a, b) => a.x?.localeCompare(b.x));
    newList.push(o);
  });
  return newList;
};

const extractRegions = (response) => {
  const regionsWithDuplicates =
    response.map((e) => e.region_name.trim()).sort() || [];
  const regions = [...new Set(regionsWithDuplicates)];
  regions.unshift('All');
  return regions;
};

const auditsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAuditRules: builder.query({
      query: () => `/audits/field_audit/rules`,
      transformErrorResponse: handleRTKQError,
      providesTags: ['AuditRules'],
    }),
    fetchSchoolAuditRules: builder.query({
      query: (siteId) => `/audits/field_audit/rules?site_id=${siteId}`,
      transformErrorResponse: handleRTKQError,
      providesTags: ['AuditRules'],
    }),
    fetchSchoolAudits: builder.query({
      query: (siteId) => `/audits/field_audit?site_id=${siteId}`,
      transformErrorResponse: handleRTKQError,
      providesTags: ['AuditBySchool'],
    }),
    fetchAuditErrorRateByField: builder.query({
      query: () => `/audits/error_rate/field`,
      transformResponse: (response) => {
        const regions = extractRegions(response);
        return {
          regions,
          errorRate: response,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['AuditErrorRateByField'],
    }),
    fetchErrorRateBySchool: builder.query({
      query: () => `/audits/error_rate/school`,
      transformResponse: (response) => {
        const regions = extractRegions(response);
        return {
          regions,
          errorRate: response.sort((a, b) => b.errors - a.errors),
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['ErrorRateBySchool'],
    }),
    fetchErrorRateSnapshot: builder.query({
      query: () => `/audits/error_rate/school/snapshot?days=14`,
      transformResponse: (response) => {
        const res = transformSnapshotResponse(response, 'school');
        return res;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['ErrorRateSnapshot'],
    }),
    fetchSchoolErrorRateWithStatus: builder.query({
      query: (siteId) => `/audits/error_rate_with_status?site_id=${siteId}`,
      transformResponse: (response) => {
        return response.sort((a, b) => b.total - a.total);
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['SchoolErrorRateWithStatus'],
    }),
    fetchSchoolErrorRateSnapshot: builder.query({
      query: (siteId) =>
        `/audits/error_rate/field/snapshot?site_id=${siteId}&days=14`,
      transformResponse: (response) => {
        const res = transformSnapshotResponse(response, 'field');
        return res;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['SchoolErrorRateSnapshot'],
    }),
    fetchStudentData: builder.query({
      query: (studentId) => `/students?student_number=${studentId}`,
      transformResponse: (response) => {
        return response?.[0];
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['StudentData'],
    }),
    fetchStudentAuditData: builder.query({
      query: (studentId) => `/audits/field_audit?student_number=${studentId}`,
      transformResponse: (response) => {
        const listOfAudits = response.map((e) => {
          if (e.failed_after_fix && e.fix_status === 'fixed') {
            e.fix_status = 'failed_after_fix';
          }
          return e;
        });
        const errorTypes = listOfAudits.map((e) => e.error_type) || [];
        const uniqueErrorTypes = [...new Set(errorTypes)];
        return {
          audits: listOfAudits,
          auditCategories: uniqueErrorTypes,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['StudentAuditData'],
    }),
    setAuditStatus: builder.mutation({
      query: ({auditId, status}) => ({
        url: `/audits/field_audit/${auditId}/fix_status`,
        method: 'PUT',
        body: {status},
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['AuditBySchool', 'StudentAuditData'],
    }),
    setAuditComment: builder.mutation({
      query: ({auditId, comment}) => ({
        url: `/audits/field_audit/${auditId}/comment`,
        method: comment ? 'PUT' : 'DELETE',
        body: {comment},
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['AuditBySchool'],
    }),
  }),
});

export const {
  useFetchAuditRulesQuery,
  useFetchSchoolAuditRulesQuery,
  useFetchSchoolAuditsQuery,
  useFetchAuditErrorRateByFieldQuery,
  useFetchErrorRateBySchoolQuery,
  useFetchErrorRateSnapshotQuery,
  useFetchSchoolErrorRateWithStatusQuery,
  useFetchSchoolErrorRateSnapshotQuery,
  useFetchStudentDataQuery,
  useFetchStudentAuditDataQuery,
  useSetAuditStatusMutation,
  useSetAuditCommentMutation,
} = auditsApi;
