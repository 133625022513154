import {datadogRum} from '@datadog/browser-rum';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';

import App from './App';
import './App.less';
import auth from './Auth';
import * as serviceWorker from './serviceWorker';
import {store} from './store/store';

const user = auth.getUser();

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'track',
  env: process.env.REACT_APP_ENVIRONMENT,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  forwardErrorsToLogs: true,
  version: process.env.REACT_APP_VERSION,
});
datadogRum.setUser({
  id: user?.sub,
  email: user?.email,
});

const root = createRoot(document.getElementById('root'));
root.render(
  // <ErrorBoundary
  //   fallback={'An error has occurred'}
  //   onError={(error) => {
  //     captureException(error);
  //   }}
  // >
  <Provider store={store}>
    <App />
  </Provider>
  // </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
