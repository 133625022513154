import Auth from 'Auth';
import {Alert, Divider} from 'antd';
import {useFetchSchoolsQuery} from 'api/apiSlice';
import LastRefresh from 'components/LastRefresh';
import SchoolDropdown from 'components/SchoolDropdown';
import StaffRolesTable from 'components/StaffRolesTable';
import TaskComplete from 'components/TaskComplete';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const StaffRolesPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {data} = useFetchSchoolsQuery();

  const chosenSchoolAbbv = location.pathname.split('/')[2];
  const [chosenSchool, setChosenSchool] = useState(null);

  useEffect(() => {
    if (data) {
      let chosenSchool;
      if (data.schools.length === 1) {
        chosenSchool = 0;
        if (!chosenSchoolAbbv) {
          navigate(`/staff_roles/${data.schools[0].abbreviation}`);
        }
      } else {
        chosenSchool = data.schools.find(
          (e) => e.abbreviation === chosenSchoolAbbv
        );
      }
      setChosenSchool(chosenSchool);
    }
  }, [navigate, data]);

  const onSchoolSelectChange = (newSchool) => {
    // We want to move to a new url for the school, plus we want to clear the filters.
    setChosenSchool(newSchool);
    navigate(`/staff_roles/${newSchool.abbreviation}`);
  };

  return (
    <>
      <h1>Staff Roles</h1>

      {/* Display the school selection and audit table when we have school data. */}
      {data && (
        <div>
          <LastRefresh />

          <SchoolDropdown
            onSchoolSelectChange={onSchoolSelectChange}
            chosenSchool={chosenSchool}
            key={chosenSchool?.site_id}
            schools={data.schools}
          />
        </div>
      )}

      {/* This implements the actual School Setup Wizard. TODO: Split to component? */}
      {data && !!chosenSchool && (
        <>
          <Divider />
          <div className="guidance">
            <Alert
              message="Staff Roles"
              description={
                <div>
                  <p>
                    Below is a list of all staff at your school according to
                    Workday. It is refreshed nightly so if you add a new staff
                    member to Workday today, they will appear tomorrow. The
                    complete timeline for the creation/updating of staff
                    accounts can be found{' '}
                    <a href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/7713265037581">
                      here.
                    </a>
                  </p>
                  <p>
                    <a href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/7695943437453">
                      Click here for full documentation of this page, including
                      usernames and default passwords for each platform.
                    </a>
                  </p>

                  <p>Key Definitions:</p>
                  <ul>
                    <li>
                      <strong>Provisioned</strong>: This staff member has an
                      active account with access to your school.
                    </li>
                    <li>
                      <strong>No Access Required: </strong>
                      Based on this staff member’s role in Workday, they do not
                      need an account in this platform. If your staff member was
                      given an account in this platform despite their title not
                      requiring one, it will still indicate No Access Required,
                      even though they have an active account.
                    </li>
                    <li>
                      <strong>In Progress: </strong>
                      This staff member doesn’t have an account in this platform
                      but, because of their role in Workday, they should.
                      ITSupport has been notified and their account will be
                      created according to the timeline linked above.
                    </li>
                  </ul>

                  <p>Additional Application Notes:</p>
                  <ul>
                    <li>
                      <strong>SchoolMessenger: </strong>
                      As a reminder, all schools have a school-specific
                      SchoolMessenger Account linked to your school’s info email
                      account.
                    </li>
                    <li>
                      <strong>Kickboard:</strong>{' '}
                      <a href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/115004598443-Kickboard-Adding-a-New-Staff-Member">
                        How to Add staff,
                      </a>{' '}
                      <a href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/115004596003-Kickboard-Deactivating-a-Staff-Member">
                        How to Remove staff
                      </a>
                    </li>
                  </ul>

                  <p>
                    <strong>New for 23-24!</strong>
                  </p>
                  <ol>
                    <li>
                      New Teachers will receive both PowerTeacher and
                      PowerSchool Admin access
                    </li>
                    <li>
                      As a reminder, passwords are managed separately for each
                      and changes to one do not impact the other
                    </li>
                    <li>
                      Password reset or other access requests should be sent to{' '}
                      <a href="mailto:ITSupport@uncommonschools.org">
                        ITSupport@uncommonschools.org
                      </a>
                    </li>
                  </ol>
                </div>
              }
              type="success"
            />
          </div>

          <br />
          <h2>{chosenSchool?.abbreviation}</h2>

          {Auth.accessToEsoy() && (
            <TaskComplete
              siteId={chosenSchool?.site_id}
              taskName="staff_app_roles"
              isTaskCompletable={() => true}
              taskIncompleteableMessage={<>Mark page as reviewed.</>}
              taskCompleteableMessage={<>Mark page as reviewed.</>}
            />
          )}
          <StaffRolesTable
            key={chosenSchool?.site_id}
            siteId={chosenSchool?.site_id}
          />
        </>
      )}
    </>
  );
};

export default StaffRolesPage;
