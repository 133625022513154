import {createApi, fetchBaseQuery, retry} from '@reduxjs/toolkit/query/react';
import Auth from 'Auth';
import {TRACK_API_BASE_URL} from 'consts';
import {handleRTKQError} from 'errorHandler';

const staggeredBaseQuery = retry(
  async (args, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: TRACK_API_BASE_URL,
      prepareHeaders: (headers) => {
        const token = Auth.getToken();
        if (token) {
          headers.set('Authorization', `${token}`);
        }
        return headers;
      },
    })(args, api, extraOptions);
    return result;
  },
  {
    retryCondition: (error, args, extraArgs) => {
      // if this is the 3rd attempt - don't retry
      // bail out of re-tries immediately if unauthorized,
      // because we know successive re-retries would be redundant
      if (
        extraArgs.attempt >= 3 ||
        error.originalStatus === 401 ||
        error.originalStatus === 403
      ) {
        return false;
      } else {
        return true;
      }
    },
    retryDelay: (attempt) => Math.pow(2, attempt) * 1000,
  }
);

const extractRegions = (response) => {
  let regionsWithDuplicates = response
    .map((school) => school.region?.trim())
    .sort();
  const regions = [...new Set(regionsWithDuplicates)];
  regions.unshift('All');
  return regions;
};

export const emptySplitApi = createApi({
  reducerPath: 'api',
  baseQuery: staggeredBaseQuery,
  tagTypes: [
    'AggregateReportMeta',
    'AggregateReportsList',
    'AuditBySchool',
    'AuditErrorRateByField',
    'AuditErrorRateBySchool',
    'AuditRules',
    'Auth',
    'CmsCategories',
    'CmsPages',
    'CmsTags',
    'ColumnOptions',
    'CourseSelection',
    'DataReportsMeta',
    'ErrorRateBySchool',
    'ErrorRateSnapshot',
    'Gradespan',
    'HomeroomAssignments',
    'Homerooms',
    'HSScheduling',
    'LatestDownloadsInfo',
    'LookupUcpid',
    'PersonDetails',
    'Pipeline',
    'PretStats',
    'ReportSourceColumns',
    'ReportSources',
    'School',
    'SchoolErrorRateSnapshot',
    'SchoolErrorRateWithStatus',
    'SchoolPretStudents',
    'Schools',
    'SeedCategories',
    'SeedData',
    'SeedMetaData',
    'SeedTags',
    'Seeds',
    'SingleAggregateReport',
    'SingleReport',
    'StaffProvisioningStatus',
    'StudentAuditData',
    'StudentData',
    'SupersetToken',
    'TaskCompletionStats',
    'TaskStatus',
    'TaskStatusBySchool',
    'TeacherAssignments',
  ],
  endpoints: () => ({}),
});
export const apiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchPipeline: builder.query({
      query: () => `/pipeline`,
      transformResponse: (response) => {
        const newStart = new Date(Date.parse(response[0].job_start + 'Z'));
        const usaTime = newStart.toLocaleString('en-US', {
          timeZone: 'America/New_York',
        });
        return usaTime;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['Pipeline'],
    }),
    fetchSchool: builder.query({
      query: (siteId) => `/schools/${siteId}`,
      transformResponse: (response) => {
        const school = response[0];
        return {
          siteId: school?.site_id,
          existingLowGrade: school?.low_grade,
          existingHighGrade: school?.high_grade,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'School', id: arg}],
    }),
    fetchSchools: builder.query({
      query: () => `/schools`,
      transformResponse: (response) => {
        function cmp(a, b) {
          if (b.abbreviation === null) return -1;
          if (a.abbreviation > b.abbreviation) return 1;
          if (a.abbreviation < b.abbreviation) return -1;
          return 0;
        }

        const regions = extractRegions(response);
        const data = {
          regions,
          schools: response.sort(cmp),
        };
        return data;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['Schools'],
    }),
    fetchHSScheduling: builder.query({
      query: (siteId) => `/hs_scheduling/${siteId}`,
      transformResponse: (response) => {
        let options = {};
        for (let i = 0; i < response.length; i++) {
          let course = response[i]; // Add this department if it doesn't exist.
          !(course.department in options) && (options[course.department] = []);
          let courseToAdd = {
            course: course.next_course_number,
            grade: course.grade,
            num_sections:
              course.section_count.draft !== null
                ? course.section_count.draft
                : course.section_count.final,
            students_in_section:
              course.max_students_per_section.draft !== null
                ? course.max_students_per_section.draft
                : course.max_students_per_section.final,
          };
          options[course.department].push(courseToAdd);
        }
        const results = response?.map((e) => {
          let course_name_display =
            e.next_course !== 'non_ap'
              ? e.next_course
              : `Non AP ${e.department} Course`;
          e = {
            course_name_display,
            ...e,
          };
          return e;
        });
        const filterOptions = [
          ...new Set(results.map((e) => e.department)),
        ].map((e) => {
          return {
            text: e,
            value: e,
          };
        });
        return {results, options, filterOptions};
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'HSScheduling', id: arg}],
    }),
    fetchStaffProvisioningStatus: builder.query({
      query: (siteId) =>
        `/staff_provisioning_status/v2${
          siteId !== 'all' ? `?system_site_id=${siteId}&site_id=${siteId}` : ''
        }`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'StaffProvisioningStatus', id: arg},
      ],
    }),
    lookupUcpid: builder.query({
      query: (ucpid) => `/ucpid/search?q=${ucpid}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'LookupUcpid', id: arg}],
    }),
    fetchPersonDetails: builder.query({
      query: (ucpid) => `/ucpid/${ucpid}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'PersonDetails', id: arg}],
    }),
    mapPersonUcpid: builder.mutation({
      query: ({primaryUcpid, ucpidToMerge}) => ({
        url: `/ucpid/${ucpidToMerge}`,
        method: 'POST',
        body: {primary_ucpid: primaryUcpid},
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => [
        {type: 'LookupUcpid', id: arg.ucpidToMerge},
        {type: 'LookupUcpid', id: arg.primaryUcpid},
        {type: 'PersonDetails', id: arg.ucpidToMerge},
        {type: 'PersonDetails', id: arg.primaryUcpid},
      ],
    }),
    batchMapUcpids: builder.mutation({
      query: (ucpids) => ({
        url: `/ucpid/merge_ucpids/batch`,
        method: 'POST',
        body: ucpids,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['LookupUcpid', 'PersonDetails'],
    }),
    setPersonDetails: builder.mutation({
      query: ({ucpid, details}) => ({
        url: `/ucpid/${ucpid}`,
        method: 'POST',
        body: details,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => [
        {type: 'LookupUcpid', id: arg.ucpid},
        {type: 'PersonDetails', id: arg.ucpid},
      ],
    }),
    setHSScheduleDraft: builder.mutation({
      query: ({siteId, schedule}) => ({
        url: `/hs_scheduling/draft/${siteId}`,
        method: 'POST',
        body: schedule,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => [
        {type: 'HSScheduling', id: arg.siteId},
      ],
    }),
    saveHSSchedule: builder.mutation({
      query: (siteId) => ({
        url: `/hs_scheduling/save/${siteId}`,
        method: 'PUT',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => [
        {type: 'HSScheduling', id: arg},
      ],
    }),
    loadOptimalSchedule: builder.mutation({
      query: (siteId) => ({
        url: `/hs_scheduling/draft/${siteId}/load_optimal`,
        method: 'PUT',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => [
        {type: 'HSScheduling', id: arg},
      ],
    }),
  }),
});

export const {
  useFetchPipelineQuery,
  useFetchSchoolQuery,
  useFetchSchoolsQuery,
  useFetchHSSchedulingQuery,
  useFetchStaffProvisioningStatusQuery,
  useLazyLookupUcpidQuery,
  useFetchPersonDetailsQuery,
  useMapPersonUcpidMutation,
  useBatchMapUcpidsMutation,
  useSetPersonDetailsMutation,
  useSetHSScheduleDraftMutation,
  useSaveHSScheduleMutation,
  useLoadOptimalScheduleMutation,
} = apiSlice;
