import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import {generateUniqueId} from 'Utils';
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Drawer,
  Modal,
  Popconfirm,
  Spin,
  Switch,
  Tooltip,
  message,
} from 'antd';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useDeletePageMutation,
  useFetchCategoriesQuery,
  useFetchPageQuery,
  useFetchTagsQuery,
  useSavePageMutation,
  useUpdatePageMutation,
} from 'api/cmsSlice';
import PageVersionItem from 'components/dataPortal/PageVersionItem';
import WidgetForm from 'components/dataPortal/WidgetForm';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useLocation, useNavigate} from 'react-router-dom';

const {Panel} = Collapse;

const PageForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pageSlug =
    location.pathname.split('/')[2] !== 'new'
      ? location.pathname.split('/')[2]
      : '';

  const [drawerVisible, setDrawerVisible] = useState(true);
  const [leftPosition, setLeftPosition] = useState('0%');
  const [localPage, setLocalPage] = useState();
  const [localWidgets, setLocalWidgets] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newPageTitle, setNewPageTitle] = useState('');

  const {data, isLoading} = useFetchPageQuery(
    {
      page_slug: pageSlug,
      version: location.search.split('=')[1] || 'latest',
    },
    {skip: !pageSlug}
  );
  const {data: tags} = useFetchTagsQuery();
  const {data: categories} = useFetchCategoriesQuery();

  const [savePage] = useSavePageMutation();
  const [deletePage] = useDeletePageMutation();
  const [updatePage] = useUpdatePageMutation();
  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createTag] = useCreateOrUpdateTagMutation();

  useEffect(() => {
    setLeftPosition(drawerVisible ? '600px' : '0%');
  }, [drawerVisible]);

  useEffect(() => {
    if (!data || _.isEqual(data, localPage)) return;
    setLocalPage({
      ...data,
    });
    setLocalWidgets(data.widgets);
  }, [data]);

  const handleAddWidget = () => {
    const widgets = localWidgets || [];
    const newWidget = {
      position: widgets.length,
      page: localPage?.id,
      id: generateUniqueId(widgets, 'int'),
    };
    setLocalWidgets([...widgets, newWidget]);
  };

  const handleDeleteWidget = (widgetId) => {
    const updatedWidgets = localWidgets.filter(
      (widget) => widget.id !== widgetId
    );
    setLocalWidgets(
      updatedWidgets.map((widget, index) => ({
        ...widget,
        position: index,
      }))
    );
  };

  const handleWidgetChanged = (value, field, widgetId) => {
    const updatedWidgets = [...localWidgets];
    const widgetIndex = updatedWidgets.findIndex((w) => w.id === widgetId);
    updatedWidgets[widgetIndex] = {
      ...updatedWidgets[widgetIndex],
      [field]: value,
    };
    setLocalWidgets(updatedWidgets);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedWidgets = [...localWidgets];
    const [removed] = updatedWidgets.splice(result.source.index, 1);
    updatedWidgets.splice(result.destination.index, 0, removed);
    setLocalWidgets(
      updatedWidgets.map((widget, index) => ({
        ...widget,
        position: index,
      }))
    );
  };

  const handlePageChanged = (value, type) => {
    const temp = {
      ...localPage,
      [type]: value,
    };
    setLocalPage(temp);
  };

  const handleSave = () => {
    const page = {
      ...localPage,
      // delete local_id from widgets
      widgets: localWidgets.map((widget) => {
        const {local_id, ...rest} = widget;
        return rest;
      }),
    };
    if (_.isEqual(page, data)) {
      message.info('No changes to save');
      return;
    }
    savePage(page)
      .unwrap()
      .then((res) => {
        message.success('Page saved successfully');
        navigate(`/data_portal/${res.slug}/editor`);
      });
  };

  const handleDelete = (version) => {
    if (!pageSlug) {
      message.error('Cannot delete a new page');
    }
    if (localPage?.published && localPage?.published_version === version) {
      message.error('Unpublish the page before deleting');
      return;
    }
    if (pageSlug) {
      deletePage({
        page_slug: pageSlug,
        version,
      })
        .unwrap()
        .then(() => {
          message.success('Page deleted successfully');
        });
    }
    if (!version) navigate(`/data_portal/editor`);
  };

  const handleClone = () => {
    if (!pageSlug) {
      message.error('Cannot clone a new page. Save this page first.');
    }
    const page = {
      title: newPageTitle,
      category: localPage?.category,
      tags: localPage?.tags,
      widgets: localWidgets,
    };
    savePage(page)
      .unwrap()
      .then((res) => {
        message.success('Page cloned successfully');
        setModalOpen(false);
        navigate(`/data_portal/${res.slug}/editor`);
      });
  };

  return (
    <>
      <Drawer
        closable={true}
        extra={
          <Switch
            loading={isLoading}
            disabled={isLoading}
            checked={localPage?.published}
            onChange={() =>
              updatePage({...localPage, published: !localPage?.published})
            }
            checkedChildren="Unpublish"
            unCheckedChildren="Publish"
            type={localPage?.published ? 'danger' : 'primary'}
          />
        }
        getContainer={false}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        placement="left"
        style={{
          position: 'fixed',
          overflowY: 'auto',
          minHeight: 'calc(100% - 64px)',
          boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        }}
        title="Source Schema Editor"
        width={600}
      >
        <Spin spinning={isLoading}>
          <div className="flex-column">
            <InputField
              label="Title"
              onChange={(e) => handlePageChanged(e.target.value, 'title')}
              required={true}
              style={{width: '300px'}}
              value={localPage?.title}
            />
            <SelectOptions
              changeSelection={(value) => handlePageChanged(value, 'category')}
              label="Category"
              onAdd={(name) => createOrUpdateCategory({name})}
              options={categories?.children}
              selectedOption={localPage?.category?.id}
              tree={true}
            />
            <SelectOptions
              changeSelection={(value) =>
                handlePageChanged(
                  value.map((v) => tags?.find((c) => c.id === v) || null),
                  'tags'
                )
              }
              label="Tags"
              onAdd={(name) => createTag({name})}
              options={tags?.map((c) => ({
                value: c.id,
                label: c.name,
                color: c.color,
              }))}
              mode="tags"
              multiple
              selectedOption={localPage?.tags?.map((t) => t.id)}
              style={{width: '100%'}}
            />
          </div>
          <Divider />
          <Alert
            description={
              <div>
                <h4>Superset:</h4>
                <ol>
                  <li>
                    <b>Dashboard ID</b>: go to the dashboard you want to embed
                    and copy the number that appears after <b>'/dashboard/'</b>{' '}
                    in the URL.
                  </li>
                  <li>
                    <b>Embed ID</b>: go to the dashboard you want to embed,
                    click on <b>'Embed dashboard'</b> option, and copy the ID.
                  </li>
                </ol>
                <h4>Google Sheets:</h4>
                <ol>
                  <li>Open the Google Sheet you want to embed in your page.</li>
                  <li>
                    Go to <b>File</b> &gt; <b>Share</b> &gt;{' '}
                    <b>Publish to web</b>.
                  </li>
                  <li>Select the sheet to publish.</li>
                  <li>
                    Select <b>Web page</b> format.
                  </li>
                  <li>Copy the link provided.</li>
                </ol>
              </div>
            }
            type="success"
            style={{marginBottom: '20px'}}
          />
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="widgets">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="flex-column"
                >
                  {localWidgets?.map((widget) => (
                    <WidgetForm
                      key={widget.id || 'new'}
                      widget={widget}
                      setWidget={(value, field) =>
                        handleWidgetChanged(value, field, widget.id)
                      }
                      deleteWidget={handleDeleteWidget}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div>
            <Button
              type="primary"
              onClick={handleAddWidget}
              style={{marginTop: '10px'}}
            >
              Add Widget
            </Button>
          </div>
          <Divider />
          <div className="flex-row">
            <Button type="primary" onClick={handleSave}>
              Save Page
            </Button>
            <Button onClick={() => setModalOpen(true)}>Duplicate Page</Button>
            <Popconfirm
              cancelText="No"
              okText="Yes"
              onConfirm={() => handleDelete()}
              title="Are you sure you want to delete this page?"
            >
              <Button type="danger">Delete Page</Button>
            </Popconfirm>
          </div>
          {localPage?.version_history?.length > 1 ? (
            <Collapse
              ghost
              style={{marginTop: '20px'}}
              expandIconPosition="end"
            >
              <Panel header="Version History" key="1">
                {localPage?.version_history?.map((version) => {
                  return (
                    <PageVersionItem
                      currentVersion={localPage.version}
                      key={version.version}
                      publishedVersion={localPage.published_version}
                      version={version}
                    />
                  );
                })}
              </Panel>
            </Collapse>
          ) : null}
        </Spin>
      </Drawer>
      <Tooltip title="Page Editor" placement="right">
        <Button
          onClick={() => setDrawerVisible(!drawerVisible)}
          type="primary"
          style={{
            top: '15%',
            height: '30%',
            left: leftPosition,
            position: 'fixed',
            zIndex: 1000,
            fontSize: '1.8em',
          }}
        >
          {drawerVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </Button>
      </Tooltip>
      <Modal
        onCancel={() => setModalOpen(false)}
        onOk={handleClone}
        open={modalOpen}
        title="Duplicate Page"
      >
        <InputField
          label="New Page Title"
          onChange={(e) => setNewPageTitle(e.target.value)}
          required={true}
          value={newPageTitle}
        />
      </Modal>
    </>
  );
};

export default PageForm;
