class Auth {
  isLoggedIn() {
    return !!localStorage.getItem('token') && !!localStorage.getItem('user');
  }

  getPermissions() {
    return {
      can_view_all_schools: this.canViewAllSchools(),
      access_to_esoy: this.accessToEsoy(),
      access_to_esoy_overview: this.accessToEsoyOverview(),
      access_to_data_reports: this.accessToDataReports(),
      access_to_data_reports_editor: this.accessToDataReportsEditor(),
      access_to_staff_account_status: this.accessToStaffAccountStatus(),
      access_to_hs_schedule: this.accessToHsSchedule(),
      access_to_source_manager: this.accessToSourceManager(),
      access_to_source_manager_editor: this.accessToSourceManagerEditor(),
      access_to_data_portal: this.accessToDataPortal(),
      access_to_data_portal_editor: this.accessToDataPortalEditor(),
      access_to_search: this.accessToSearch(),
    };
  }

  // Callback for when the login is finished.
  finishLogin(data) {
    localStorage.setItem('all_response', JSON.stringify(data));
    localStorage.setItem('token', data.access_token);
    localStorage.setItem('user', JSON.stringify(data.user_info));
    localStorage.setItem('ps_site_code', JSON.stringify(data.ps_site_code));
    localStorage.setItem(
      'can_view_all_schools',
      JSON.stringify(data.can_view_all_schools === true)
    );
    localStorage.setItem(
      'can_view_esoy_overview',
      JSON.stringify(data.can_view_esoy_overview === true)
    );
    localStorage.setItem(
      'access_to_esoy',
      JSON.stringify(data.access_to_esoy === true)
    );
    localStorage.setItem(
      'access_to_data_reports',
      JSON.stringify(
        data.access_to_data_reports === true ||
          data.access_to_data_reports_editor === true
      )
    );
    localStorage.setItem(
      'access_to_data_reports_editor',
      JSON.stringify(data.access_to_data_reports_editor === true)
    );
    localStorage.setItem(
      'access_to_staff_account_status',
      JSON.stringify(data.access_to_staff_account_status === true)
    );
    localStorage.setItem(
      'access_to_hs_schedule',
      JSON.stringify(data.access_to_hs_schedule === true)
    );
    localStorage.setItem(
      'access_to_source_manager',
      JSON.stringify(
        data.access_to_source_manager === true ||
          data.access_to_source_manager_editor === true
      )
    );
    localStorage.setItem(
      'access_to_source_manager_editor',
      JSON.stringify(data.access_to_source_manager_editor === true)
    );
    localStorage.setItem(
      'access_to_search',
      JSON.stringify(data.access_to_search === true)
    );

    let redirect = localStorage.getItem('redirect');
    if (redirect) {
      window.location.href = redirect;
      localStorage.removeItem('redirect');
      return;
    } else {
      // Redirect back to the home page. User should now be logged in.
      window.location.href = '/';
    }
  }

  passwordLogin(data) {
    localStorage.setItem('token', data.access_token);
  }

  otpLogin(data) {
    localStorage.setItem('user', JSON.stringify(data.user_info));
    localStorage.setItem(
      'access_to_data_reports',
      JSON.stringify(
        data.access_to_data_reports === true ||
          data.access_to_data_reports_editor === true
      )
    );
    localStorage.setItem(
      'access_to_data_reports_editor',
      JSON.stringify(data.access_to_data_reports_editor === true)
    );
  }

  logout(redirect = true) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('all_response');

    if (redirect) {
      window.location.href = '/';
    }
  }

  getUser() {
    const user = localStorage.getItem('user');
    if (!user || user === 'undefined') {
      return null;
    }
    return JSON.parse(user);
  }

  canViewAllSchools() {
    return JSON.parse(localStorage.getItem('can_view_all_schools')) === true;
  }

  accessToEsoy() {
    return JSON.parse(localStorage.getItem('access_to_esoy')) === true;
  }

  accessToEsoyOverview() {
    return JSON.parse(localStorage.getItem('can_view_esoy_overview')) === true;
  }

  accessToDataReports() {
    return JSON.parse(localStorage.getItem('access_to_data_reports')) === true;
  }

  accessToDataReportsEditor() {
    return (
      JSON.parse(localStorage.getItem('access_to_data_reports_editor')) === true
    );
  }

  accessToStaffAccountStatus() {
    return (
      JSON.parse(localStorage.getItem('access_to_staff_account_status')) ===
      true
    );
  }

  accessToHsSchedule() {
    return JSON.parse(localStorage.getItem('access_to_hs_schedule')) === true;
  }

  accessToSourceManager() {
    return (
      JSON.parse(localStorage.getItem('access_to_source_manager')) === true
    );
  }

  accessToSourceManagerEditor() {
    return (
      JSON.parse(localStorage.getItem('access_to_source_manager_editor')) ===
      true
    );
  }

  accessToDataPortal() {
    return true;
  }

  accessToDataPortalEditor() {
    return true;
  }

  accessToSearch() {
    return JSON.parse(localStorage.getItem('access_to_search')) === true;
  }

  getToken() {
    if (!localStorage.getItem('token')) {
      return null;
    }
    return 'Token ' + localStorage.getItem('token');
  }

  hasPermission(route) {
    const path = route.split('/')[1];
    switch (path) {
      case '':
      case 'login':
      case 'login_failed':
      case 'logout':
        return true;
      case 'login_done':
      case 'audits':
      case 'audit_rules':
      case 'audits_overview':
      case 'audits_issue_overview':
      case 'audits_school_summary':
        return this.getUser() && this.getToken();
      case 'school_setup':
        return this.getUser() && this.getToken() && this.accessToEsoy();
      case 'esoy_it_support':
        return this.getUser() && this.getToken() && this.accessToEsoyOverview();
      case 'staff_roles':
        return (
          this.getUser() && this.getToken() && this.accessToStaffAccountStatus()
        );
      case 'staff_roles_it_support':
        return (
          this.getUser() &&
          this.getToken() &&
          this.accessToStaffAccountStatus() &&
          this.accessToEsoyOverview()
        );
      case 'data_report':
        return this.getUser() && this.getToken() && this.accessToDataReports();
      case 'data_report_editor':
        return (
          this.getUser() &&
          this.getToken() &&
          this.accessToDataReports() &&
          this.accessToDataReportsEditor()
        );
      case 'hs_schedule':
        return this.getUser() && this.getToken() && this.accessToHsSchedule();
      case 'source_manager':
      case 'source_manager_changes':
        return (
          this.getUser() && this.getToken() && this.accessToSourceManager()
        );
      case 'person':
        return this.getUser() && this.getToken();
      case 'data_portal':
        return this.getUser() && this.getToken() && this.accessToDataPortal();
      case 'search':
        return this.getUser() && this.getToken() && this.accessToSearch();
      default:
        return true;
    }
  }
}

const auth = new Auth();
export default auth;
