import {Button, Card, Col, Row, Select, Spin, Tabs} from 'antd';
import {
  useFetchStudentAuditDataQuery,
  useFetchStudentDataQuery,
  useSetAuditStatusMutation,
} from 'api/auditsSlice';
import FieldComment from 'components/audits/FieldComment';
import React, {useEffect, useState} from 'react';

const {Option} = Select;

const StudentDetails = ({studentId, fieldAuditId}) => {
  const [defaultAuditCategory, setDefaultAuditCategory] = useState();
  const [defaultAuditField, setDefaultAuditField] = useState();

  const {data: studentData, isLoading: studentDataLoading} =
    useFetchStudentDataQuery(studentId);

  const {data: studentAuditData, isLoading: studentAuditDataLoading} =
    useFetchStudentAuditDataQuery(studentId);

  const [setAuditStatus] = useSetAuditStatusMutation();

  useEffect(() => {
    if (fieldAuditId && studentAuditData?.audits) {
      let a = studentAuditData.audits.find(
        (e) => e.field_audit_id === fieldAuditId
      );
      if (!a) {
        return;
      }
      setDefaultAuditCategory(a.error_type);
      setDefaultAuditField(a.field);
    }
  }, []);

  const getAuditsByCat = (cat) => {
    return studentAuditData?.audits?.filter((e) => e.error_type === cat);
  };

  const setAuditFixStatus = (audit, status) => {
    setAuditStatus({auditId: audit.field_audit_id, status});
  };

  return (
    <div>
      <Spin spinning={studentDataLoading}>
        <div style={{minHeight: '200px'}}>
          {studentData && (
            <Card
              title={'Student: ' + studentData.student_name}
              style={{
                lineHeight: '5px',
              }}
            >
              <div
                style={{
                  overflowY: 'scroll',
                  height: '200px',
                  lineHeight: '10px',
                }}
              >
                <Row>
                  <Col span={12}>
                    <p>
                      <strong>Student Number:</strong>&nbsp;
                      {studentData.student_number}
                    </p>
                    <p>
                      <strong>School Number:</strong>&nbsp;
                      {studentData.site_id}
                    </p>
                    <p>
                      <strong>School:</strong>&nbsp;
                      {studentData.school}
                    </p>
                    <p>
                      <strong>Home Address Street:</strong>
                      &nbsp;
                      {studentData.street}
                    </p>
                    <p>
                      <strong>Home Address City:</strong>
                      &nbsp;{studentData.city}
                    </p>
                    <p>
                      <strong>Home Address State:</strong>
                      &nbsp;
                      {studentData.address_state}
                    </p>
                    <p>
                      <strong>Home Address Zip:</strong>&nbsp;
                      {studentData.zip}
                    </p>
                  </Col>
                  <Col span={12}>
                    <p>
                      <strong>Mailing Address Street:</strong>
                      &nbsp;
                      {studentData.mailing_street}
                    </p>
                    <p>
                      <strong>Mailing Address City:</strong>
                      &nbsp;
                      {studentData.mailing_city}
                    </p>
                    <p>
                      <strong>Mailing Address State:</strong>
                      &nbsp;
                      {studentData.mailing_state}
                    </p>
                    <p>
                      <strong>Mailing Address Zip:</strong>
                      &nbsp;
                      {studentData.mailing_zip}
                    </p>
                    <p>
                      <strong>Date of Birth:</strong>&nbsp;
                      {studentData.dob}
                    </p>
                    <p>
                      <strong>Gender:</strong>&nbsp;
                      {studentData.gender}
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          )}
        </div>
      </Spin>

      <Spin spinning={studentAuditDataLoading}>
        <Tabs
          defaultActiveKey={defaultAuditCategory}
          tabPosition="top"
          color="Black"
          items={studentAuditData?.auditCategories?.map((cat) => ({
            key: cat,
            label: cat,
            children: (
              <div
                style={{
                  overflowY: 'scroll',
                  height: '400px',
                }}
              >
                <Tabs
                  defaultActiveKey={defaultAuditField}
                  tabPosition="left"
                  items={getAuditsByCat(cat).map((audit) => ({
                    key: audit.field,
                    label: audit.field,
                    children: (
                      <div>
                        <h3>{audit.field}</h3>
                        <h4>Status:</h4>
                        &nbsp;
                        <Select // showSearch
                          style={{
                            width: 200,
                            marginBottom: '10px',
                          }}
                          optionFilterProp="label"
                          onChange={(e) => setAuditFixStatus(audit, e)}
                          value={audit.fix_status}
                          size="small"
                        >
                          <Option value="active">Active</Option>
                          <Option value="fixed">Fixed</Option>
                          <Option value="fix_later">Can't Fix</Option>
                          <Option value="failed_after_fix" disabled="true">
                            Failed after fix
                          </Option>
                        </Select>
                        <h4>Description</h4>
                        <div>
                          {audit.error_description.map((text) => {
                            return (
                              <ol key={text}>
                                <li>{text}</li>
                              </ol>
                            );
                          })}
                        </div>
                        <h4>Current Field Value</h4>
                        <p>{audit.field_value ? audit.field_value : 'none'}</p>
                        <h4>Comment on Field Audit</h4>
                        <FieldComment
                          comment={audit.comment ? audit.comment.comment : ''}
                          auditId={audit.field_audit_id}
                        />
                      </div>
                    ),
                  }))}
                />
                <Button type="primary" style={{marginTop: 10}}>
                  <a
                    href={
                      studentAuditData?.audits?.filter(
                        (e) => e.error_type === cat
                      )?.[0]?.link
                    }
                    target="fixstudent"
                  >
                    Fix in Power School
                  </a>
                </Button>
              </div>
            ),
          }))}
        />
      </Spin>
    </div>
  );
};

export default StudentDetails;
