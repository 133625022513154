import {ExclamationCircleFilled, SearchOutlined} from '@ant-design/icons';
import {ResponsiveBar} from '@nivo/bar';
import {Alert, Button, Input, Modal, Select, Spin, Table, Tooltip} from 'antd';
import {
  useFetchSchoolPretStudentsQuery,
  useSetMultipleStudentsReviewStatusMutation,
  useSetStudentReviewStatusMutation,
} from 'api/esoySlice';
import TaskComplete from 'components/TaskComplete';
import {GRADES} from 'consts';
import React, {useEffect, useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

const {Option} = Select;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const barColors = {
  Promote: 'hsl(122, 85%, 79%)',
  Transfer: 'hsl(39, 97%, 65%)',
  Retain: 'hsl(61, 83%, 82%)',
  Exit: 'hsl(0, 84%, 81%)',
  'Not Reviewed': 'hsl(0, 0%, 100%)',
};

const getColor = (bar) => barColors[bar.id];

function ESoyStatusBarGraph({graphData, gradespan}) {
  const getDataForGraph = () => {
    if (graphData.length === 0 || !gradespan) {
      return graphData;
    } // Filter the students to only the ones whose grade is the given grade..

    let allData = [];
    gradespan.forEach((grade) => {
      let data = graphData.filter((e) => e.grade_level === grade);
      let promote = 0;
      let retain = 0;
      let transfer = 0;
      let exit = 0;
      let unassigned = 0; // For this school, let's count the different stats.

      promote = data.filter(
        (e) => e.pret_review_status === 'review_promote'
      ).length;
      retain = data.filter(
        (e) => e.pret_review_status === 'review_retain'
      ).length;
      transfer = data.filter(
        (e) => e.pret_review_status === 'review_transfer'
      ).length;
      exit = data.filter((e) => e.pret_review_status === 'review_exit').length;
      unassigned =
        data.filter((e) => e.pret_review_status === 'unreviewed').length +
        data.filter((e) => e.pret_review_status === '').length;
      data = {
        Grade: grade,
        Promote: promote,
        Retain: retain,
        Transfer: transfer,
        Exit: exit,
        'Not Reviewed': unassigned,
      };
      allData.unshift(data);
    });
    return allData;
  };

  return (
    <ResponsiveBar
      data={getDataForGraph(graphData)}
      keys={['Promote', 'Retain', 'Transfer', 'Exit', 'Not Reviewed']}
      indexBy="Grade"
      margin={{top: 50, right: 130, bottom: 50, left: 60}}
      padding={0.3}
      layout="horizontal"
      valueScale={{type: 'linear'}}
      indexScale={{type: 'band', round: true}}
      colors={getColor}
      height={300}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'fries',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'sandwich',
          },
          id: 'lines',
        },
      ]}
      borderColor={{from: 'color', modifiers: [['darker', 0.6]]}}
      borderWidth={1}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Review status by grade',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      labelSkipWidth={60}
      labelSkipHeight={12}
      labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
      label={(d) => {
        return `${d.value} ${d.id}`;
      }}
      legends={[
        {
          dataFrom: ['Grade 1 (promote)'],
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          symbolSize: 20,
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
}

const EsoyPretTable = ({siteId}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [fixingStudent, setFixingStudent] = useState(null);
  const [fixModalVisible, setFixModalVisible] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [taskComplete, setTaskComplete] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 50,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} students`,
    showSizeChanger: true,
    onChange: (page, pageSize) => {
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
      });
    },
    onShowSizeChange: (current, size) => {
      setPagination({
        ...pagination,
        current: current,
        pageSize: size,
      });
    },
  });
  const [modalNewReviewStatus, setModalNewReviewStatus] = useState();
  const [multiRowSelectedRows, setMultiRowSelectedRows] = useState([]);

  const {data: fetchedData, isLoading} = useFetchSchoolPretStudentsQuery(
    siteId,
    {
      skip: !siteId,
    }
  );
  const [setMultipleStudentsReviewStatus] =
    useSetMultipleStudentsReviewStatusMutation();
  const [setStudentReviewStatus] = useSetStudentReviewStatusMutation();

  useEffect(() => {
    setSearchParams();
  }, [sortedInfo, filteredInfo]);

  useEffect(() => {
    const params = location.search.split('?');
    let filters = {...filteredInfo};
    if (params) {
      if ('sortOrder' in params && 'sortColumn' in params) {
        setSortedInfo({
          order: params.sortOrder,
          columnKey: params.sortColumn,
        });
      }

      for (const p in params) {
        if (p.startsWith('filter_')) {
          let colname = p.slice('filter_'.length);
          if (!filteredInfo) {
            setFilteredInfo({});
          }
          if (!filters[colname]) {
            filters[colname] = [];
          }

          filters[colname].push(params[p]);
        }
      }
      setFilteredInfo(filters);

      if (params.fixingStudent) {
        setFixingStudent(params.fixingStudent);
        setFixModalVisible(true);
      }
    }
  }, []);

  const searchInput = useRef();

  const fixitModalHandleOk = () => {
    changeStudentReviewStatus(modalNewReviewStatus, fixingStudent);
    setFixModalVisible(false);
    setSearchParams();
  };

  const fixitModalHandleCancel = () => {
    setFixModalVisible(false);
    setSearchParams();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <div className="flex-row">
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Filter
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}
          >
            Clear
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getStudentById = (id) => {
    let student = fetchedData?.tableData.find((e) => e.student_id === id);
    return student;
  };

  const changeStudentReviewStatus = (newStatus, studentId) => {
    setStudentReviewStatus({studentId, newStatus, siteId});
  };

  const onReviewStatusChange = (newStatus, studentId) => {
    // If the status is promote, make the change right away:
    if (newStatus === 'review_promote' || newStatus === 'unreviewed') {
      changeStudentReviewStatus(newStatus, studentId);
    } // If it's a different status, we need to show instructions, and only change if they
    // are accepted.
    else {
      setFixingStudent(studentId);
      setModalNewReviewStatus(newStatus);
      setFixModalVisible(true);
    }
  };

  const setSearchParams = () => {
    let search = '?';
    if (sortedInfo.field) {
      search +=
        'sortColumn=' + sortedInfo.field + '&sortOrder=' + sortedInfo.order;
    }

    for (const field in filteredInfo) {
      let f = filteredInfo[field];

      if (f) {
        for (const entry in f) {
          search += `&filter_${field}=${f[entry]}`;
        }
      }
    }

    navigate(search);
  };

  const getGradesForFilter = () => {
    return GRADES.map((e) => {
      return {
        text: e,
        value: e,
      };
    });
  };

  const getStatusesForFilter = () => {
    return [
      {
        text: 'Promote',
        value: 'Promote',
      },
      {
        text: 'Retain',
        value: 'Retain',
      },
      {
        text: 'Transfer',
        value: 'Transfer',
      },
      {
        text: 'Exit',
        value: 'Exit',
      },
    ];
  };

  const getSmStatusesForFilter = () => {
    // Get list of available sm statuses
    if (!fetchedData?.tableData) return [];
    let ret = [...new Set(fetchedData?.tableData.map((e) => e.sm_status))];
    ret = ret.map((e) => {
      return {
        text: e,
        value: e,
      };
    });
    return ret;
  };

  const getReviewStatusesForFilter = () => {
    return [
      {
        text: 'Unreviewed',
        value: 'unreviewed',
      },
      {
        text: 'Reviewed - Promote',
        value: 'review_promote',
      },
      {
        text: 'Reviewed - Retain',
        value: 'review_retain',
      },
      {
        text: 'Reviewed - Transfer',
        value: 'review_transfer',
      },
      {
        text: 'Reviewed - Exit',
        value: 'review_exit',
      },
    ];
  };

  const getSchoolsForFilter = (type) => {
    if (!fetchedData?.tableData) return [];
    let schools = [...new Set(fetchedData?.tableData.map((e) => e[type]))];
    schools.sort();
    let schoolsFilter = schools.map((school) => {
      return {
        text: school,
        value: school,
      };
    });
    return schoolsFilter;
  };

  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'student_name',
      key: 'student_name',
      width: 120,
      sorter: (a, b) => a.student_name.localeCompare(b.student_name),
      ...getColumnSearchProps('student_name'),
    },
    {
      title: 'Student Number',
      dataIndex: 'student_number',
      key: 'student_number',
      width: 120,
      align: 'right',
      sorter: (a, b) => a.student_number - b.student_number,
      ...getColumnSearchProps('student_number'),
    },
    {
      title: 'Current School',
      dataIndex: 'school',
      key: 'school',
      width: 100,
      sorter: (a, b) => a.school.localeCompare(b.school),
      filters: getSchoolsForFilter('school'),
      onFilter: (value, record) => record.school.toString() === value,
    },
    {
      title: 'PS Next School',
      dataIndex: 'next_school',
      key: 'next_school',
      width: 100,
      sorter: (a, b) => {
        if (a.next_school && b.next_school) {
          return a.next_school.localeCompare(b.next_school);
        } else {
          return 0;
        }
      },
      filters: getSchoolsForFilter('next_school'),
      onFilter: (value, record) => record.next_school.toString() === value,
    },
    {
      title: 'SM Next School',
      dataIndex: 'sm_next_year_school',
      key: 'sm_next_year_school',
      width: 100,
      sorter: (a, b) => {
        if (a.sm_next_year_school) {
          if (b.sm_next_year_school) {
            return a.sm_next_year_school.localeCompare(b.sm_next_year_school);
          }
        } else {
          return 0;
        }
      },
      filters: getSchoolsForFilter('sm_next_year_school'),
      onFilter: (value, record) =>
        record.sm_next_year_school.toString() === value,
    },
    {
      title: 'Grade',
      dataIndex: 'grade_level_display',
      key: 'grade_level_display',
      width: 100,
      align: 'right',
      sorter: (a, b) => a.grade_level - b.grade_level,
      filters: getGradesForFilter(),
      onFilter: (value, record) => record.grade_level.toString() === value,
    },
    {
      title: 'Next Year Grade',
      dataIndex: 'next_year_grade_display',
      key: 'next_year_grade_display',
      width: 100,
      align: 'right',
      sorter: (a, b) => a.next_year_grade - b.next_year_grade,
      filters: getGradesForFilter(),
      onFilter: (value, record) => record.next_year_grade.toString() === value,
    },
    {
      title: 'Exit Date',
      dataIndex: 'exit_date_display',
      key: 'exitdate',
      width: 100,
      align: 'right',
      sorter: (a, b) => a.exit_date_display.localeCompare(b.exit_date_display),
      ...getColumnSearchProps('exit_date_display'),
    },
    {
      title: 'PS Projected Status',
      dataIndex: 'pret_calculate_status_display',
      key: 'pret_calculate_status_display',
      width: 120,
      sorter: (a, b) =>
        a.pret_calculate_status_display.localeCompare(
          b.pret_calculate_status_display
        ),
      filters: getStatusesForFilter(),
      onFilter: (value, record) =>
        record.pret_calculate_status_display === value,
    },
    {
      title: 'SM Status',
      dataIndex: 'sm_status_display',
      key: 'sm_status_display',
      width: 120,
      sorter: (a, b) => a.sm_status_display.localeCompare(b.sm_status_display),
      filters: getSmStatusesForFilter(),
      onFilter: (value, record) => record.sm_status_display === value,
      render: (text, record) => (
        <Tooltip title={record.sm_decline_reason}>
          {text}{' '}
          {record.sm_decline_reason && (
            <span
              style={{
                color: 'red',
                fontSize: '10px',
              }}
            >
              (?)
            </span>
          )}
        </Tooltip>
      ),
    },
    {
      title: 'Review Status',
      dataIndex: 'review_status_display',
      key: 'action',
      sorter: (a, b) =>
        a.review_status_display.localeCompare(b.review_status_display),
      filters: getReviewStatusesForFilter(),
      onFilter: (value, record) => record.review_status_display === value,
      width: 200,
      render: (review_status_display, record) => {
        return (
          <Select
            style={{
              width: 180,
            }}
            placeholder="Review student..."
            optionFilterProp="label"
            onChange={(e) => onReviewStatusChange(e, record.student_id)}
            value={record.review_status_display}
          >
            <Option value="unreviewed" key="unreviewed" label="unreviewed">
              Not reviewed
            </Option>
            <Option
              value="review_promote"
              key="review_promote"
              label="review_promote"
            >
              Reviewed - Promote
            </Option>
            <Option
              value="review_retain"
              key="review_retain"
              label="review_retain"
            >
              Reviewed - Retain
            </Option>
            <Option
              value="review_transfer"
              key="review_transfer"
              label="review_transfer"
            >
              Reviewed - Transfer
            </Option>
            <Option value="review_exit" key="review_exit" label="review_exit">
              Reviewed - Exit
            </Option>
          </Select>
        );
      },
    },
    {
      title: 'Review Error?',
      dataIndex: 'pret_review_error',
      key: 'pret_review_error',
      width: 100,
      align: 'right',
      render: (text, record) => {
        const errorText = record.pret_review_error_description.split(',');
        const tooltipText =
          errorText.length > 1
            ? errorText.map((e) => <p key={e}>{e}</p>)
            : errorText;
        return (
          <>
            {text && (
              <Tooltip title={tooltipText}>
                <ExclamationCircleFilled
                  style={{
                    color: 'red',
                  }}
                />
              </Tooltip>
            )}
          </>
        );
      },
      sorter: (a, b) => a.pret_review_error - b.pret_review_error,
      filters: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      onFilter: (value, record) => record.pret_review_error === value,
    },
  ];

  const multiPromoteStudents = () => {
    let studentsToPromote = multiRowSelectedRows.map((e) => {
      return {
        student_id: e.student_id,
        status: 'review_promote',
      };
    });
    setMultipleStudentsReviewStatus({
      students: studentsToPromote,
      siteId,
    });
  };

  const multiUnreviewStudents = () => {
    let studentsToPromote = multiRowSelectedRows.map((e) => {
      return {
        student_id: e.student_id,
        status: 'unreviewed',
      };
    });
    setMultipleStudentsReviewStatus({
      students: studentsToPromote,
      siteId,
    });
  };

  const taskCanBeCompleted = () => {
    if (!fetchedData?.tableData) return false;
    if (taskComplete) return false; // Already done.
    // If we have our students, check if they're all reviewed and have no errors.

    let allStudentsReviewed = fetchedData?.tableData.every((e) => {
      return (
        e.pret_review_status !== 'unreviewed' &&
        e.pret_review_status !== '' &&
        e.pret_review_error === false
      );
    });
    return allStudentsReviewed;
  };

  return (
    <div className="esoy-pret-table">
      <div>
        <Alert
          message="Next Year Student Status"
          description={
            <div>
              <p>
                Review each student and choose their Next Year status from the
                dropdown. For retentions, transfers, and exits, follow the
                directions in the pop-up box to make updates directly in
                PowerSchool.
              </p>

              <ul>
                <li>
                  Reviewed - Promote: Next year, the student will be one grade
                  level above the grade they are in now
                </li>
                <ul>
                  <li>
                    Note: If a Promotion in Doubt student will not have a
                    retention decision made by the deadline, mark them as
                    “Reviewed - Promote”
                  </li>
                </ul>
                <li>
                  Reviewed - Retain: Next year, the student will remain in the
                  current grade level
                </li>
                <li>
                  Reviewed - Transfer: Next year, the student will attend a
                  different Uncommon school within your region
                </li>
                <ul>
                  <li>
                    Note: This does not apply to rising 5th or 9th graders
                    attending their feeder school who should be marked as
                    “Reviewed - Promote”
                  </li>
                  <li>
                    Note: Please refer to regional guidance on transfer policies
                  </li>
                </ul>
                <li>
                  Reviewed - Exit: Next year, the student will not attend
                  Uncommon
                </li>
              </ul>
              <p>
                Click{' '}
                <a
                  href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/360060946051/"
                  target="_"
                >
                  here
                </a>{' '}
                to view our kBase article with more details, screenshots, and
                examples.
              </p>
            </div>
          }
          type="success"
        />
        <br />
      </div>

      <Container>
        {fetchedData?.tableData && (
          <span>
            <strong>Total students: &nbsp;</strong>
            {fetchedData?.length}
          </span>
        )}

        <TaskComplete
          key="next_year_student_status"
          siteId={siteId}
          taskName="next_year_student_status"
          setCompletionStatus={setTaskComplete}
          isTaskCompletable={() => taskCanBeCompleted()}
          taskIncompleteableMessage={
            <>
              Can only mark task as completed when all students are reviewed and
              there are no review errors.
            </>
          }
          taskCompleteableMessage={
            <>
              <p>All students reviewed, task can be marked as completed.</p>
              <p>This cannot be undone without contacting IT Support.</p>
            </>
          }
        />
      </Container>

      <Spin spinning={isLoading}>
        <div
          style={{
            height: fetchedData?.length ? '300px' : '0px',
          }}
        >
          {fetchedData?.tableData && (
            <ESoyStatusBarGraph
              graphData={fetchedData?.tableData}
              gradespan={fetchedData?.gradespan}
            />
          )}
        </div>
      </Spin>

      <Table
        dataSource={fetchedData?.tableData}
        columns={columns}
        pagination={pagination}
        size="small"
        rowKey="student_id"
        loading={isLoading}
        bordered
        scroll={{
          x: '100%',
          y: '80vh',
        }}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) => {
            setMultiRowSelectedRows(selectedRows);
          },
        }}
        rowClassName={(student) => {
          return [
            student.pret_review_status,
            student.pret_review_error ? 'review-error' : '',
          ];
        }}
        title={(currentPageData) => {
          return (
            <div>
              <div>Currently displayed: {currentPageData.length}</div>
              <hr></hr>
              <div>
                <div className="flex-row">
                  <Button
                    type="primary"
                    disabled={
                      multiRowSelectedRows && multiRowSelectedRows.length === 0
                    }
                    onClick={() => {
                      multiPromoteStudents();
                    }}
                  >
                    Assign selected to "Reviewed - Promote"
                  </Button>
                  <Button
                    type="primary"
                    disabled={
                      multiRowSelectedRows && multiRowSelectedRows.length === 0
                    }
                    onClick={() => {
                      multiUnreviewStudents();
                    }}
                  >
                    Assign selected to "Not Reviewed"
                  </Button>
                </div>
              </div>
            </div>
          );
        }}
      />

      {/* Student Details Modal Overlay */}
      <Modal
        title="Change student's next year status"
        open={fixModalVisible}
        width={1000}
        onOk={fixitModalHandleOk}
        onCancel={fixitModalHandleCancel}
        footer={[
          <Button key="back" onClick={fixitModalHandleOk}>
            Confirm Change Made in Powerschool
          </Button>,
        ]}
      >
        {/* Retain */}
        {modalNewReviewStatus === 'review_retain' && (
          <div>
            <p>
              Next year, the student will remain in the current grade level.
            </p>
            <ol>
              <li>Sign into PowerSchool in a new tab</li>
              <li>
                In TRACK, click <strong>Update in PowerSchool</strong>
              </li>
              <li>
                In PowerSchool, update the field "Next Year Grade" to match
                their current grade
              </li>
              <li>
                (For retained 4th and 8th graders only) In PowerSchool, update
                the field "Next School Indicator" to match their current school
              </li>
              <li>
                Click <strong>Submit </strong>in PowerSchool
              </li>
              <li>
                In TRACK, click{' '}
                <strong>Confirm Change Made in PowerSchool</strong>
                <ul>
                  <li>
                    If you click out of this box without this step, it will not
                    save the updated status
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              Click{' '}
              <a
                href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/360060946051/"
                target="_"
              >
                here
              </a>{' '}
              to view our kBase article with more details, screenshots, and
              examples.
            </p>
            <Button type="primary">
              <a
                href={
                  getStudentById(fixingStudent).ps_instance +
                  '/admin/students/schedulesetup.html?frn=001' +
                  getStudentById(fixingStudent).student_local_id
                }
                target="fixstudentpret"
              >
                Update in PowerSchool
              </a>
            </Button>
          </div>
        )}

        {/* Transfer */}
        {modalNewReviewStatus === 'review_transfer' && (
          <div>
            <p>
              Next year, the student will attend a different Uncommon school
              within your region.
              <ul>
                <il>
                  <i>Please refer to regional guidance on transfer policies</i>
                </il>
              </ul>
            </p>
            <p>
              * Note: This does not apply to rising 5th or 9th graders attending
              their feeder school who should be marked as “Reviewed - Promote”.
            </p>
            <ol>
              <li>Sign into PowerSchool in a new tab</li>
              <li>
                In TRACK, click <strong>Update in PowerSchool</strong>
              </li>
              <li>
                In PowerSchool, update the field "Next School Indicator" to
                choose the school they will be attending next year.
              </li>
              <li>
                Click <strong>Submit </strong>in PowerSchool
              </li>
              <li>
                In TRACK, click{' '}
                <strong>Confirm Change Made in PowerSchool</strong>
                <ul>
                  <li>
                    If you click out of this box without this step, it will not
                    save the updated status
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              Click{' '}
              <a
                href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/360060946051/"
                target="_"
              >
                here
              </a>{' '}
              to view our kBase article with more details, screenshots, and
              examples.
            </p>

            <Button type="primary">
              <a
                href={
                  getStudentById(fixingStudent).ps_instance +
                  '/admin/students/schedulesetup.html?frn=001' +
                  getStudentById(fixingStudent).student_local_id
                }
                target="fixstudentpret"
              >
                Update in PowerSchool
              </a>
            </Button>
          </div>
        )}

        {/* Exit */}
        {modalNewReviewStatus === 'review_exit' &&
          (getStudentById(fixingStudent).ps_state === 'MA' ? ( // MA helptext
            <div>
              <p>Next year, the student will not attend Uncommon</p>
              <ol>
                <li>Sign into PowerSchool in a new tab</li>
                <li>
                  In TRACK, click <strong>Update in PowerSchool</strong>
                </li>
                <li>
                  In PowerSchool, fill out the required Exit Information
                  <a
                    href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/360040179271-Transfer-Exit-Codes#h_01G8VDWQZPD47EXHZ0BG009B67"
                    target="_"
                  >
                    {' '}
                    (more info here)
                  </a>
                  <ul>
                    <li>
                      <strong>Exit Destination</strong>
                    </li>
                    <li>
                      <strong>Exit Reason</strong>
                    </li>
                    <li>
                      <strong>Exit Code</strong>
                    </li>
                    <li>
                      <strong>Exit Comment</strong> (must include the student's
                      next school or city/state where student is moving)
                    </li>
                    <li>
                      DO NOT EDIT <strong>Exit Date Field</strong> as this will
                      negatively impact state reporting
                    </li>
                  </ul>
                </li>
                <li>
                  Click <strong>Submit </strong>in PowerSchool
                </li>
                <li>
                  In TRACK, click{' '}
                  <strong>Confirm Change Made in PowerSchool</strong>
                  <ul>
                    <li>
                      If you click out of this box without this step, it will
                      not save the updated status
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                Click{' '}
                <a
                  href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/360060946051/"
                  target="_"
                >
                  here
                </a>{' '}
                to view our kBase article with more details, screenshots, and
                examples.
              </p>

              <Button type="primary">
                <a
                  href={
                    getStudentById(fixingStudent).ps_instance +
                    '/admin/students/edittransfer1.html?frn=001' +
                    getStudentById(fixingStudent).student_local_id
                  }
                  target="fixstudentpret"
                >
                  Update in PowerSchool
                </a>
              </Button>
            </div> // NOT MA helptext
          ) : (
            <div>
              <p>Next year, the student will not attend Uncommon</p>
              <ol>
                <li>Sign into PowerSchool in a new tab</li>
                <li>
                  In TRACK, click <strong>Update in PowerSchool</strong>
                </li>
                <li>
                  In PowerSchool, fill out the required Information{' '}
                  <a
                    href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/360040179271-Transfer-Exit-Codes#h_01G8VDWQZPD47EXHZ0BG009B67"
                    target="_"
                  >
                    (more info here)
                  </a>
                  <ul>
                    <li>
                      <strong>Transfer Comment </strong>
                      (Be sure to include the school the student is transferring
                      to or city/state where student is moving)
                    </li>
                    <li>
                      <strong>Exit Destination</strong>
                    </li>
                    <li>
                      <strong>Exit Reason</strong>
                    </li>
                    <li>
                      <strong>Exit Code</strong>
                    </li>
                    <li>
                      <strong>Date of Transfer</strong> (Use 6/22/24 - this will
                      ensure the student is not transferred out until after
                      report cards are completed)
                    </li>
                  </ul>
                </li>
                <li>
                  Click <strong>Submit </strong>in PowerSchool
                </li>
                <li>
                  In TRACK, click{' '}
                  <strong>Confirm Change Made in PowerSchool</strong>
                  <ul>
                    <li>
                      If you click out of this box without this step, it will
                      not save the updated status
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                Click{' '}
                <a
                  href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/360060946051/"
                  target="_"
                >
                  here
                </a>{' '}
                to view our kBase article with more details, screenshots, and
                examples.
              </p>

              <Button type="primary">
                <a
                  href={
                    getStudentById(fixingStudent).ps_instance +
                    '/admin/students/transferout.html?frn=001' +
                    getStudentById(fixingStudent).student_local_id
                  }
                  target="fixstudentpret"
                >
                  Update in PowerSchool
                </a>
              </Button>
            </div>
          ))}
      </Modal>
    </div>
  );
};

export default EsoyPretTable;
