import {Divider, Tabs} from 'antd';
import {useFetchSchoolsQuery} from 'api/apiSlice';
import LastRefresh from 'components/LastRefresh';
import SchoolDropdown from 'components/SchoolDropdown';
import AssignHomerooms from 'components/esoy/AssignHomerooms';
import AssignTeachers from 'components/esoy/AssignTeachers';
import CoursesSelect from 'components/esoy/CoursesSelect';
import EoyChecks from 'components/esoy/EoyChecks';
import EsoyPretTable from 'components/esoy/EsoyPretTable';
import SetupHomerooms from 'components/esoy/SetupHomerooms';
import {ROLLOVER_MODE} from 'consts';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const guidanceHTML = `
<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;mso-yfti-tbllook:1184'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:.25in'>
  <td valign=top style='border:solid black 1.0pt;background:#073763;padding:
  2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:white;
  mso-ansi-language:EN-US'>Platform</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:
  solid black 1.0pt;background:#073763;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:.25in'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:white;
  mso-ansi-language:EN-US'>Process</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:
  solid black 1.0pt;background:#073763;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:.25in'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:white;
  mso-ansi-language:EN-US'>Grade Span</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:
  solid black 1.0pt;background:#073763;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:.25in'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:white;
  mso-ansi-language:EN-US'>Task</span><span style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-ansi-language:
  EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border:solid black 1.0pt;
  border-left:none;mso-border-left-alt:solid black 1.0pt;background:#073763;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:white;
  mso-ansi-language:EN-US'>Owner</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=120 valign=top style='width:1.25in;border:solid black 1.0pt;
  border-left:none;mso-border-left-alt:solid black 1.0pt;background:#0B5394;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:white;
  mso-ansi-language:EN-US'>Task Distributed on</span><span style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=108 valign=top style='width:81.0pt;border:solid black 1.0pt;
  border-left:none;mso-border-left-alt:solid black 1.0pt;background:#0B5394;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:white;
  mso-ansi-language:EN-US'>Deadline</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:1;height:8.25pt'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:8.25pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>PS</span><span style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-ansi-language:
  EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:8.25pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Pre-Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:8.25pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>All</span><span style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-ansi-language:
  EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:8.25pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Finalize Q4 Report Cards</span><span
  style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
  "Times New Roman";mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:8.25pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>RC Owner</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=120 style='width:1.25in;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;background:white;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:8.25pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Thurs, 5/16</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=108 style='width:81.0pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;background:white;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:8.25pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Tues, 6/18</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:2;height:13.0pt'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>TRACK</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Pre-Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>ES/MS</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Complete EOY Checks in TRACK</span><span
  style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
  "Times New Roman";mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>DOO/POC</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=120 rowspan=2 style='width:1.25in;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;background:white;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Mon, 5/20</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=108 rowspan=2 style='width:81.0pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;background:white;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Tues, 6/18</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:3;height:13.0pt'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>TRACK</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Pre-Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>ES/MS</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Next Year Student Status</span><span
  style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
  "Times New Roman";mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>DOO/POC</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:4;height:13.0pt'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>TRACK</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Pre-Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>ES/MS</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Setup Homeroom</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><i><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Optional (but encouraged!) to start pre-shutdown</span></i><span
  style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
  "Times New Roman";mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>DOO/POC</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=120 rowspan=2 style='width:1.25in;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;background:white;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Mon, 5/20</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=108 rowspan=2 style='width:81.0pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;background:white;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Post-Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:5;height:13.0pt'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>TRACK</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Pre-Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>ES/MS</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Assign Homerooms</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><i><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Optional (but encouraged!) to start pre-shutdown!</span></i><span
  style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
  "Times New Roman";mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>DOO/POC</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:6'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>PS</span><span style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-ansi-language:
  EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Pre-Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>HS</span><span style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-ansi-language:
  EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>HS Schedule Commit</span><span style='font-size:
  12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  background:white;padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>HS DOO</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=120 style='width:1.25in;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;background:white;
  padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Mon, 6/3</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=108 style='width:81.0pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;background:white;
  padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Tues, 6/18</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:7;height:30.75pt'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;background:yellow;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:30.75pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>OPS SHUT DOWN</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=617 colspan=4 style='width:463.1pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  background:yellow;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:30.75pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>You will NOT be able to work in TRACK during Ops
  Shutdown</span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
  mso-fareast-font-family:"Times New Roman";mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=228 colspan=2 style='width:171.0pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  background:yellow;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:30.75pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>6/22-7/21</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:8;height:13.0pt'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>TRACK</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Post Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>ES/MS</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Setup Homerooms</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>DOO/POC</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=120 rowspan=4 style='width:1.25in;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Mon, 7/22</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=108 rowspan=4 style='width:81.0pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Fri, 8/9</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:9;height:13.0pt'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>TRACK</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Post Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>ES/MS</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Assign Homerooms</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>DOO/POC</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:10;height:13.0pt'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>TRACK</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Post Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>ES/MS</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Offer Courses</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>DOO/POC</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:11;height:13.0pt'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>TRACK</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Post Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>ES/MS</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
  height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Assign Teachers</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  padding:2.0pt 2.0pt 2.0pt 2.0pt;height:13.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>DOO/POC</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:12;mso-yfti-lastrow:yes'>
  <td valign=top style='border:solid black 1.0pt;border-top:none;mso-border-top-alt:
  solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>SchoolMint</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Post Shutdown</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>All</span><span style='font-size:12.0pt;font-family:
  "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-ansi-language:
  EN-US'><o:p></o:p></span></p>
  </td>
  <td valign=top style='border-top:none;border-left:none;border-bottom:solid black 1.0pt;
  border-right:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
  mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Sync New Students</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=174 valign=top style='width:130.2pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
  padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>DOO/POC</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=120 style='width:1.25in;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Mon, 7/29</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
  <td width=108 style='width:81.0pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
  solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
  style='font-size:9.0pt;font-family:"Libre Franklin";mso-fareast-font-family:
  "Times New Roman";mso-bidi-font-family:"Times New Roman";color:black;
  mso-ansi-language:EN-US'>Ongoing</span><span style='font-size:12.0pt;
  font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
  mso-ansi-language:EN-US'><o:p></o:p></span></p>
  </td>
 </tr>
</table>`;

const EsoyOverview = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {data} = useFetchSchoolsQuery();
  const chosenSchoolAbbv = location.pathname.split('/')[2];
  const [chosenSchool, setChosenSchool] = useState(null);

  useEffect(() => {
    if (data) {
      let newSchool = null;
      if (data.schools.length === 1) {
        newSchool = data.schools[0];
        if (!chosenSchoolAbbv) {
          navigate(`/school_setup/${data.schools[0].abbreviation}`);
        }
      } else {
        newSchool = data.schools.find(
          (e) => e.abbreviation === chosenSchoolAbbv
        );
      }
      setChosenSchool(newSchool);
    }
  }, [navigate, data]);

  const onSchoolSelectChange = (newSchool) => {
    // We want to move to a new url for the school, plus we want to clear the filters.
    setChosenSchool(newSchool);
    navigate(`/school_setup/${newSchool.abbreviation}`);
  };

  return (
    <div>
      <h1>E/SOY</h1>
      {/* Display the school selection and audit table when we have school data. */}
      {data && (
        <div>
          <LastRefresh />
          <SchoolDropdown
            onSchoolSelectChange={onSchoolSelectChange}
            chosenSchool={chosenSchool}
            key={chosenSchool?.site_id}
            schools={data.schools}
            filters={{gradespan: ['ES', 'MS']}}
          />
        </div>
      )}

      {/* This implements the actual School Setup Wizard. TODO: Split to component? */}
      {data && !!chosenSchoolAbbv && (
        <>
          <Divider />
          <h2>{chosenSchoolAbbv}</h2>

          <Tabs
            defaultActiveKey="guidance"
            destroyInactiveTabPane={true}
            items={[
              {
                label: 'Guidance',
                key: 'guidance',
                children: (
                  <div>
                    <p>
                      Welcome to E/SOY! Below are the timelines for this year’s
                      tasks.
                    </p>
                    <p>
                      Task specific guidance is linked on each of the subsequent
                      tabs, but{' '}
                      <a href="https://uncommonschoolssupport.zendesk.com/hc/en-us/categories/360001640971-E-SOY">
                        here is a direct link to the E/SOY section of the Kbase
                      </a>{' '}
                      with additional resources.
                    </p>
                    <div
                      style={{overflowX: 'auto'}}
                      dangerouslySetInnerHTML={{
                        __html: guidanceHTML,
                      }}
                    />
                  </div>
                ),
              },
              ...(ROLLOVER_MODE === 'showall' || ROLLOVER_MODE === 'pre'
                ? [
                    {
                      label: 'EOY Checks',
                      key: 'EOYchecks',
                      children: (
                        <EoyChecks
                          siteId={chosenSchool?.site_id}
                          key={chosenSchool?.site_id}
                        />
                      ),
                    },
                    {
                      label: 'NYSS',
                      key: 'NYSS',
                      children: (
                        <EsoyPretTable
                          siteId={chosenSchool?.site_id}
                          key={chosenSchool?.site_id}
                        />
                      ),
                    },
                  ]
                : []),
              {
                label: 'Setup Homerooms',
                key: 'setupHomerooms',
                children: (
                  <SetupHomerooms
                    siteId={chosenSchool?.site_id}
                    key={chosenSchool?.site_id}
                  />
                ),
              },
              ...(ROLLOVER_MODE === 'showall' || ROLLOVER_MODE === 'pre'
                ? [
                    {
                      label: 'Assign Homerooms',
                      key: 'assignHomeroomsPre',
                      children: (
                        <AssignHomerooms
                          siteId={chosenSchool?.site_id}
                          key={chosenSchool?.site_id}
                          rolloverMode="pre"
                        />
                      ),
                    },
                  ]
                : []),
              ...(ROLLOVER_MODE === 'showall' || ROLLOVER_MODE === 'post'
                ? [
                    {
                      label: 'Assign Homerooms',
                      key: 'assignHomeroomsPost',
                      children: (
                        <AssignHomerooms
                          siteId={chosenSchool?.site_id}
                          key={chosenSchool?.site_id}
                          rolloverMode="post"
                        />
                      ),
                    },
                    {
                      label: 'Offer Courses',
                      key: 'offerCourses',
                      children: (
                        <CoursesSelect
                          siteId={chosenSchool?.site_id}
                          key={chosenSchool?.site_id}
                        />
                      ),
                    },
                    {
                      label: 'Assign Teachers',
                      key: 'assignTeachers',
                      children: (
                        <AssignTeachers
                          siteId={chosenSchool?.site_id}
                          key={chosenSchool?.site_id}
                        />
                      ),
                    },
                  ]
                : []),
            ]}
          />
        </>
      )}
    </div>
  );
};

export default EsoyOverview;
