import {emptySplitApi} from 'api/apiSlice';
import {handleRTKQError} from 'errorHandler';
import _ from 'lodash';

const searchSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    setSearchToken: builder.mutation({
      query: () => ({
        url: '/search/token',
        method: 'POST',
      }),
      transformErrorResponse: handleRTKQError,
    }),
  }),
});

export const {useSetSearchTokenMutation} = searchSlice;
